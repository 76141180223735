import dayjs from "dayjs";

export const calculateStayDuration = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
        return ''
    }

    if (startDate.length < 19 || endDate.length < 19) {
        startDate = '20' + startDate.replaceAll('.', '-').replace(/[^\d-]/g, '')
        endDate = '20' + endDate.replaceAll('.', '-').replace(/[^\d-]/g, '')
    }

    const startDay = dayjs(startDate).startOf('day');
    const endDay = dayjs(endDate).startOf('day');
    const duration = endDay.diff(startDay, 'day');

    if (duration <= 0 || isNaN(duration)) {
        return '';
    }

    const nights = duration
    const days = endDay.diff(startDay, 'day') + 1

    return `${nights}박 ${days}일`;
};

export const determineWeekend = () => {
    const today = dayjs()
    return {
        startDateString: today.day(5).format('YY.MM.DD (ddd)'),
        endDateString: today.day(7).format('YY.MM.DD (ddd)'),
    }
}

export const transformToDaysString = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
        return ''
    }

    return `${dayjs(startDate).format('YY.MM.DD ddd')} ~ ${dayjs(endDate).format('YY.MM.DD ddd')} / ${calculateStayDuration(startDate, endDate)}`
}

export const transformToPeopleString = (adult: number, child: number, baby: number) => {
    const total = adult + child + baby
    if (isNaN(total)) {
        return ''
    }

    return `${total}인 (성인 ${adult}, 아동 ${child}, 유아 ${baby})`;
}

