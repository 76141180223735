import {FRONT_URL} from "../../constants/environment";


class NavigationService {
    static moveToMain() {
        window.location.href = FRONT_URL
    }

    static moveTo(path: string, navigateFunc?: any) {
        window.location.href = `${FRONT_URL}${path}`;
    }

    static scrollToTop() {
        this.scrollTo(0, 0)
    }

    static scrollTo(x: number, y: number) {
        // @ts-ignore
        document.querySelector('body').scrollTo(x, y)
    }
}

export default NavigationService
