import {Simulate} from "react-dom/test-utils";

export const moneyValue = (value: string | number) => {
    if (value === 0) {
        return 0
    }

    if (!value) {
        return ''
    }

    return value.toString()
        .replaceAll(/\D+/g, '')
        .replaceAll(',', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const copyToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text)
}

export function formatPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber || ''
    const cleaned = ('' + phoneNumber).replace(/\D+/g, '')
    const match = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/)

    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`
    }

    return cleaned
}

export function onlyNumbers(inputString: string = '') {
    // 정규 표현식을 사용하여 숫자 및 특수 문자를 제거합니다.
    return inputString.replace(/\D+/g, '');
}

export function removeNumbersAndSpecialCharacters(inputString: string = '') {
    // 정규 표현식을 사용하여 숫자 및 특수 문자를 제거합니다.
    return inputString.replace(/[0-9!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, '');
}
