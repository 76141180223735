const AUTH_TOKEN_COOKIE_NAME = 'AuthToken'

class CookieService {

    static initAccessToken() {
        document.cookie = `${AUTH_TOKEN_COOKIE_NAME}=; Max-Age=-99999999;`;
    }

    static getAccessToken() {
        return this.get(AUTH_TOKEN_COOKIE_NAME);
    }

    static get(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (!parts || parts.length !== 2) {
            return null;
        }

        const part = parts.pop();

        if (!part) {
            return null;
        }

        return part.split(';').shift() || null;
    }
}

export default CookieService;
