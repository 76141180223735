import {CSSProperties} from "react";

export const dateAndCustomerInfoContainerStyle: CSSProperties = {
    alignSelf: 'center',
    borderRadius: 8,
    width: '100%',
    height: 128,
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
}

export const dateAndCustomerInfoStyle: CSSProperties = {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 64,
    paddingLeft: 20,
    paddingRight: 20,
}
