import ApiService from "../../services/ApiService";
import {PagingAndSortingParam, pagingQuery} from "../ApiUtil";
import {Content} from "../ContentApi";

class RoomApi {

    static get(roomId: number) {
        const path = `/front/rooms/${roomId}`
        return ApiService.get(path, false)
    }
}

export default RoomApi
