import React, {useState} from "react";
import {Tabs} from "antd";
import {Color, TextStyle} from "../../../constants/style";

export const ReservationTab = ({Rooms, AccommodationInfo}: {
    Rooms: React.ReactNode,
    AccommodationInfo: React.ReactNode,
}) => {
    const [activeTab, setActiveTab] = useState("RESERVATION")

    const TabLabel = ({active, label}: { active: boolean, label: string }) => (
        <div
            style={{width: 140, color: active ? Color.darkPrimary : Color.darkTertiary, textAlign: 'center'}}
        >
            {label}
        </div>
    )
    const tabs: any = [
        {
            key: "RESERVATION",
            label: <TabLabel active={activeTab === 'RESERVATION'} label={'예약'}/>,
            children: Rooms,
        },
        {
            key: "ACCOMMODATION",
            label: <TabLabel active={activeTab === 'ACCOMMODATION'} label={'숙소 정보'}/>,
            children: AccommodationInfo,
        },
    ]

    return (
        <Tabs
            style={{marginTop: 48, width: '100%', alignItems: 'center'}}
            tabBarStyle={{...TextStyle, fontWeight: 500, fontSize: 18, color: Color.darkPrimary}}
            defaultActiveKey={"RESERVATION"}
            activeKey={activeTab}
            onChange={(activeTab) => setActiveTab(activeTab)}
            items={tabs}
        />
    )
}