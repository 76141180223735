import {Image, Space, Typography} from "antd";
import React, {CSSProperties, useEffect} from "react";
import useWindowDimensions, {Color, TextStyle} from "../../../constants/style";
import {ImageCarousel, mockImages} from "./ImageCarousel";
import HumanIcon from "../../../assets/icon/human.png";
import RoomIcon from "../../../assets/icon/room.png";
import RoomSizeIcon from "../../../assets/icon/roomSize.png";
import {moneyValue} from "../../../utils/string";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {useNavigate} from "react-router-dom";
import {determineWeekend} from "../util";
import {useDispatch, useSelector} from "react-redux";
import {requestRooms} from "../../../redux/Accommodation";

export const ReservationRooms = ({
                                     containerStyle,
                                     accommodationId,
                                     startDateString,
                                     endDateString,
                                     adult,
                                     child,
                                     baby
                                 }: {
    containerStyle?: CSSProperties,
    accommodationId: number,
    startDateString: string,
    endDateString: string,
    adult: number,
    child: number,
    baby: number,
}) => {
    const dispatch = useDispatch()
    const dateAndCustomerInfo = {
        startDateString,
        endDateString,
        adult,
        child,
        baby
    }

    useEffect(() => {
        // @ts-ignore
        dispatch(requestRooms(
            accommodationId,
            dateAndCustomerInfo?.startDateString, dateAndCustomerInfo?.endDateString,
            adult, child, baby))
    }, [accommodationId, startDateString, endDateString, adult, child, baby])

    // @ts-ignore
    const rooms = useSelector(state => state.Accommodation.get('rooms'))

    return (
        <Space direction={'vertical'} style={{flex: 1}}>
            {rooms.map((room: any, index: number) =>
                (<ReservationRoomCard
                    key={index}
                    accommodationId={accommodationId}
                    dateAndCustomerInfo={dateAndCustomerInfo}
                    room={room}/>)
            )}
        </Space>
    )
}

const ReservationRoomCard = ({accommodationId, dateAndCustomerInfo, room}: {
    accommodationId: number,
    dateAndCustomerInfo: any,
    room: any,
}) => {
    const navigate = useNavigate()

    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const numberOfPeopleString = `${room.standardNumberOfPeople} ~ ${room.maxNumberOfPeople}인`
    const numberOfRoomString = `거실 ${room.numberOfLivingRoom}, 방 ${room.numberOfRoom}, 화장실 ${room.numberOfBathroom}`
    const roomSizeString = `${room.roomSize}평`
    const roomPrice = `${moneyValue(room.price)}원 / 1박`

    return (
        <Space
            direction={'vertical'}
            style={{
                borderRadius: 20,
                marginTop: 24,
                width: isSmallWidthDevice ? deviceWidth - 40 : 372,
                height: 'auto',
                backgroundColor: Color.b1,
                paddingBottom: 20,
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
            }}
        >
            <ImageCarousel
                autoplay={false}
                showAllImageButton={false}
                topRound
                height={210}
                images={room.contents?.filter((content: any) => content.type === 'IMAGE')}

            />
            <Space
                direction={'vertical'}
                className={'clickable'}
                onClick={() => navigate(
                    PageRoutes.dynamicPath('/accommodations', accommodationId, '/rooms', room.id),
                    {state: {dateAndCustomerInfo: dateAndCustomerInfo}})}
                style={{rowGap: 0, width: '100%', paddingLeft: 20, paddingRight: 20}}
            >
                <Typography.Text style={{...TextStyle, fontSize: 20, fontWeight: 700}}>
                    {room.name}
                </Typography.Text>
                <Space direction={'vertical'} style={{rowGap: 4, marginTop: 8}}>
                    <IconLabel icon={HumanIcon} label={numberOfPeopleString}/>
                    <IconLabel icon={RoomIcon} label={numberOfRoomString}/>
                    <IconLabel icon={RoomSizeIcon} label={roomSizeString}/>
                </Space>
                <Space direction={'vertical'} style={{
                    rowGap: 4,
                    marginTop: 20,
                    width: '100%',
                    alignItems: 'end',
                    justifyContent: 'flex-end'
                }}>
                    <Typography.Text style={{...TextStyle, textAlign: 'right', fontSize: 18, fontWeight: 700}}>
                        {roomPrice}
                    </Typography.Text>
                </Space>
            </Space>
        </Space>
    )
}

const IconLabel = ({icon, label}: { icon: any, label: string }) => {
    return (
        <Space style={{alignItems: 'center', justifyContent: 'center'}}>
            <Image preview={false} style={{width: 20, height: 20, marginBottom: 1}} src={icon}/>
            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400}}>
                {label}
            </Typography.Text>
        </Space>
    )
}
