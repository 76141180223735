import React, {useState} from "react";
import {Space, Typography} from "antd";
import NoBorderButton from "../NoBorderButton";
import {Color, TextStyle} from "../../constants/style";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";


// @ts-ignore
export const LongTextExpand = ({text, maxLength}) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const displayText = expanded ? text : `${text.slice(0, maxLength)}...`;

    const ExpandButton = () => (
        <Space style={{width: '100%', justifyContent: 'end'}}>
            <NoBorderButton
                style={{...TextStyle, fontSize: 12, fontWeight: 500}}
                onClick={handleToggle}
                icon={<PlusOutlined/>}
                value={'펼치기'}/>
        </Space>
    )

    const ContractButton = () => (
        <Space style={{width: '100%', justifyContent: 'end'}}>
            <NoBorderButton
                style={{...TextStyle, fontSize: 12, fontWeight: 500}}
                onClick={handleToggle}
                icon={<MinusOutlined/>}
                value={'줄이기'}/>
        </Space>
    )

    return (
        <div style={{marginTop: 16, maxWidth: '100%'}}>
            <Typography.Text style={{
                ...TextStyle,
                fontSize: 14,
                fontWeight: 400,
                color: Color.darkSecondary
            }}>{displayText}</Typography.Text>
            {text.length > maxLength && (expanded ? <ContractButton/> : <ExpandButton/>)}
        </div>
    );
};
