import React, {CSSProperties, useEffect, useState} from "react";
import {Color, TextStyle} from "../../../constants/style";
import {Button, Carousel, Image} from "antd";
import {FALLBACK_IMAGE} from "../../../constants/content";
import {PictureFilled} from "@ant-design/icons";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {useNavigate} from "react-router-dom";
import {pad, round} from "lodash";

export const mockImages = [
    {url: "https://plus.unsplash.com/premium_photo-1669324357471-e33e71e3f3d8?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
    {url: "https://images.unsplash.com/photo-1591154669695-5f2a8d20c089?auto=format&fit=crop&q=80&w=3087&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
    {url: "https://plus.unsplash.com/premium_photo-1669324357471-e33e71e3f3d8?auto=format&fit=crop&q=80&w=2970&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
    {url: "http://unsplash.it/600/600?image=180"},
]

export const ImageCarousel = ({
                                  title = '',
                                  images,
                                  width = '100%',
                                  height = 306,
                                  topRound = false,
                                  bottomRound = false,
                                  paddingHorizontal = 0,
                                  autoplay = true,
                                  showAllImageButton = true,
                              }: {
    title?: string
    images: any[],
    width?: string | number,
    height?: number,
    topRound?: boolean,
    bottomRound?: boolean,
    paddingHorizontal?: number,
    autoplay?: boolean,
    showAllImageButton?: boolean,
}) => {
    const navigate = useNavigate()

    const [currentIndex, setCurrentIndex] = useState(0)
    const currentIndexText = `${currentIndex + 1}/${images.length} 전체보기`

    if (images.length < 1) {
        return null
    }

    let roundStyle = topRound ? 'top-round' : ''
    if (bottomRound) {
        roundStyle += ' bottom-round'
    }

    return (
        <div
            style={{
                display: 'inline-block',
                width: width,
                height: height,
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                verticalAlign: 'center',
                backgroundColor: Color.white,
            }}
            className={roundStyle}
        >
            <Carousel
                autoplay={autoplay}
                dots={!autoplay}
                swipeToSlide
                autoplaySpeed={3000}
                className={roundStyle}
                style={{
                    width: width,
                    height: height,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                afterChange={(currentSlide: number) => setCurrentIndex(currentSlide)}
            >
                {images.map((image, index) => (
                    <Image
                        key={index}
                        preview={false}
                        className={`image ${roundStyle}`}
                        fallback={FALLBACK_IMAGE}
                        width={width}
                        height={height}
                        src={image.url}
                    />
                ))}
            </Carousel>
            {showAllImageButton && (
                <Button
                    style={{...allImagesButtonStyle}}
                    icon={<PictureFilled/>}
                    onClick={() => navigate(PageRoutes.Images, {state: {headerTitle: title, images: images}})}
                >{currentIndexText}</Button>
            )}
        </div>
    )
}

const allImagesButtonStyle: CSSProperties = {
    marginTop: 5,
    marginLeft: '35%',
    marginRight: 'auto',
    maxWidth: 180,
    minHeight: 40,
    width: 'auto',
    height: 'auto',
    borderColor: Color.darkPrimary,
    borderRadius: 16,
    ...TextStyle,
    fontSize: 14,
    fontWeight: 700,
    color: Color.white,
    backgroundColor: Color.darkPrimary,
    opacity: 0.6,
}


