import dayjs from "dayjs";

export function calculatePrice(
    standardNumberOfPeople: number,
    roomPrice: number,
    additionalFeeAdult: number,
    additionalFeeChild: number,
    additionalFeeBaby: number,
    dateAndCustomerInfo: any = {}
) {
    const formatString = 'YY.MM.DD (ddd)';
    const date1 = dayjs(dateAndCustomerInfo.startDateString, formatString)
    const date2 = dayjs(dateAndCustomerInfo.endDateString, formatString)
    const days = date2.diff(date1, 'day');

    const calculatePersonPrice = () => {
        let personPrice = 0;

        // 성인의 추가 비용 계산
        personPrice += Math.max(dateAndCustomerInfo.adult - standardNumberOfPeople, 0) * additionalFeeAdult;

        // 어린이의 추가 비용 계산
        personPrice += Math.max(dateAndCustomerInfo.child - (standardNumberOfPeople - dateAndCustomerInfo.adult), 0) * additionalFeeChild;

        // 유아의 추가 비용 계산
        personPrice += Math.max(dateAndCustomerInfo.baby -
            Math.max(standardNumberOfPeople - dateAndCustomerInfo.adult - dateAndCustomerInfo.child, 0), 0) * additionalFeeBaby;

        return personPrice;
    };

    const personPrice = calculatePersonPrice()

    if (days <= 0) {
        return {
            total: roomPrice + personPrice,
            days: roomPrice,
            people: personPrice,
        }
    }

    return {
        total: roomPrice * days + personPrice,
        days: roomPrice * days,
        people: personPrice,
    }
}
