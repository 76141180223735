import React, {useState} from 'react'
import {Space, Table, Typography} from "antd";
import useWindowDimensions, {Color, TextStyle} from "../../../constants/style";
import {LongTextExpand} from "../../../components/LongTextExpand";
import {formatPhoneNumber} from "../../../utils/string";
import {
    BorderOuterOutlined,
    CheckCircleFilled,
    CheckOutlined,
    InfoCircleOutlined,
    MinusCircleFilled
} from "@ant-design/icons";
import EmptySpace from "../../../components/EmptySpace";
import {ColumnsType} from "antd/es/table";

export const AccommodationInfo = ({accommodation}: { accommodation: any }) => {
    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth - 40 : 372

    return (
        <Space direction={'vertical'} style={{rowGap: 0, width: width, marginTop: 24}}>
            <div>
                <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                    숙소 소개
                </Typography.Text>
                <LongTextExpand maxLength={100} text={accommodation?.description}/>
            </div>

            <div style={{marginTop: 24}}>
                <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                    대표 번호
                </Typography.Text>
                <div style={{marginTop: 16}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400,
                    }}>{formatPhoneNumber(accommodation?.officePhoneNumber)}</Typography.Text>
                </div>
            </div>

            <div style={{marginTop: 24}}>
                <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                    입실/퇴실
                </Typography.Text>
                <div style={{marginTop: 16}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400,
                    }}>{`입실: ${accommodation?.checkInTime}, 퇴실: ${accommodation?.checkOutTime}`}</Typography.Text>
                </div>
            </div>

            <div style={{marginTop: 24}}>
                <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                    객실 이용 및 취소/환불 정책
                </Typography.Text>
                <div style={{marginTop: 16}}>
                    <UsageAndRefundPolicy usagePolicy={accommodation?.usagePolicy}
                                          refundPolicy={accommodation?.refundPolicy}
                                          refundPercentages={accommodation?.refundPercentages}/>
                </div>
            </div>
        </Space>
    )
}

// @ts-ignore
const UsageAndRefundPolicy = ({usagePolicy, refundPolicy, refundPercentages = []}) => {
    interface CancelFeeDataType {
        key: string;
        id: number;
        day: string;
        cancelFeePercentage: string;
        refundPercentage: string;
    }

    const cancelFeeColumns: ColumnsType<CancelFeeDataType> = [
        {
            title: '기준일',
            dataIndex: 'day',
            key: 'day',
        },
        {
            title: '취소 수수료',
            dataIndex: 'cancelFeePercentage',
            key: 'cancelFeePercentage',
        },
        {
            title: '환불율',
            dataIndex: 'refundPercentage',
            key: 'refundPercentage',
        },
    ]

    const cancelFeeDataSources: CancelFeeDataType[] = refundPercentages.map((p: any) => ({
        key: `
            refund - percentage -${p.id}`,
        id: p.id,
        day: p.day,
        cancelFeePercentage: `${p.cancelFeePercentage} % `,
        refundPercentage: `${p.refundPercentage} % `,
    }))

    return (
        <Space
            direction={'vertical'}
            style={{
                borderRadius: 20,
                padding: 20,
                width: '100%',
                backgroundColor: Color.b1,
            }}
        >
            <Typography.Text style={{
                ...TextStyle,
                fontSize: 12,
                fontWeight: 700,
                color: Color.darkSecondary,
            }}>이용 정책</Typography.Text>
            <Space direction={'vertical'}>
                <Typography.Text
                    style={{
                        ...TextStyle,
                        fontSize: 12,
                        fontWeight: 400,
                        color: Color.darkSecondary
                    }}>
                    {usagePolicy}
                </Typography.Text>
            </Space>
            <EmptySpace color={Color.b1} size={20}/>
            <Typography.Text style={{
                ...TextStyle,
                fontSize: 12,
                fontWeight: 700,
                color: Color.darkSecondary,
            }}>환불 정책</Typography.Text>
            <Space direction={'vertical'}>
                <Typography.Text
                    style={{
                        ...TextStyle,
                        fontSize: 12,
                        fontWeight: 400,
                        color: Color.darkSecondary
                    }}>
                    {refundPolicy}
                </Typography.Text>
            </Space>

            <EmptySpace color={Color.b1} size={20}/>
            <Typography.Text style={{
                ...TextStyle,
                fontSize: 12,
                fontWeight: 700,
                color: Color.darkSecondary,
            }}>※ 취소 수수료</Typography.Text>
            <Space direction={'vertical'} style={{width: '100%'}}>
                <Table
                    style={{marginTop: 10}}
                    columns={cancelFeeColumns}
                    dataSource={cancelFeeDataSources}
                    pagination={false}
                />
            </Space>
        </Space>
    )
}
