import ApiService from "../../services/ApiService";
import {Reservation} from "../../redux/Reservation";

class RoomApi {

    static create(reservation: Reservation) {
        const path = `/front/reservations`
        return ApiService.post(path, reservation, false)
    }

    static get(name: string, phoneNumber: string, code: string) {
        const path = `/front/reservations/search?name=${name}&phoneNumber=${phoneNumber}&code=${code}`
        return ApiService.get(path, false)
    }
}

export default RoomApi
