import ScreenOuter from "../../components/ScreenOuter";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Form, Image, Input, message, Modal, Space, Typography} from "antd";
import useWindowDimensions, {Color, MobileSizeStyle, TextStyle} from "../../constants/style";
import {mockImages} from "../AccommodationScreen/components/ImageCarousel";
import {calculateStayDuration} from "../AccommodationScreen/util";
import {formatPhoneNumber, moneyValue, onlyNumbers, removeNumbersAndSpecialCharacters} from "../../utils/string";
import React, {useEffect, useRef, useState} from "react";
import NavigationService from "../../services/NavigationService";
import {NumberIncrement} from "../../components/NumberIncrement";
import RequiredFieldMark from "../../components/RequiredFieldMark";
import TextArea from "antd/es/input/TextArea";
import {CheckCircleOutlined, RightOutlined} from "@ant-design/icons";
import TextLiner from "../../components/TextLiner";

import CheckIcon from '../../assets/icon/check-icon.png'
import CheckIconDisabled from '../../assets/icon/check-icon-disabled.png'
import YellowSelectIcon from '../../assets/icon/yellow-select.png'
import SelectDisabledIcon from '../../assets/icon/select-disabled.png'
import {calculatePrice} from "../../utils/price";
import {PageRoutes} from "../../App";
import {requestRoom} from "../../redux/Room";
import {useDispatch, useSelector} from "react-redux";
import {createReservation} from "../../redux/Reservation";
import {convertDateFormat} from "../../utils/date";

export const ReservationScreen = ({}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    const location = useLocation()
    const reservationParam = location?.state || null

    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth - 40 : 372

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [birthday, setBirthday] = useState('')
    const [comment, setComment] = useState('')

    const [isAllAgreed, setIsAllAgreed] = useState(false)
    const [isUsageAgreed, setIsUsageAgreed] = useState(false)
    const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false)
    const [isPrivacyThirdPartyAgreed, setIsPrivacyThirdPartyAgreed] = useState(false)

    const [price, setPrice] = useState<any>({})
    const [totalPrice, setTotalPrice] = useState(0)
    const [paidFacilityResult, setPaidFacilityResult] = useState<any[]>([])
    const [paidFacilityTotalPrice, setPaidFacilityTotalPrice] = useState(0)

    useEffect(() => {
        NavigationService.scrollToTop()

        // @ts-ignore
        dispatch(requestRoom(reservationParam.roomId))
    }, [])

    // @ts-ignore
    const roomName = useSelector(state => state.Room.get('name'))
    // @ts-ignore
    const roomPrice = useSelector(state => state.Room.get('price'))
    // @ts-ignore
    const roomStandardNumberOfPeople = useSelector(state => state.Room.get('standardNumberOfPeople'))
    // @ts-ignore
    const roomMaxNumberOfPeople = useSelector(state => state.Room.get('maxNumberOfPeople'))
    // @ts-ignore
    const roomAdditionalFeeAdult = useSelector(state => state.Room.get('additionalFeeAdult'))
    // @ts-ignore
    const roomAdditionalFeeChild = useSelector(state => state.Room.get('additionalFeeChild'))
    // @ts-ignore
    const roomAdditionalFeeBaby = useSelector(state => state.Room.get('additionalFeeBaby'))
    // @ts-ignore
    const roomPaidFacilities = useSelector(state => state.Room.get('paidFacilities'))

    useEffect(() => {
        if (roomPrice) {
            const price = calculatePrice(
                roomStandardNumberOfPeople,
                roomPrice, roomAdditionalFeeAdult, roomAdditionalFeeChild,
                roomAdditionalFeeBaby, reservationParam?.dateAndCustomerInfo)
            setPrice(price)
            setTotalPrice(price.total)
        }
    }, [roomPrice])

    useEffect(() => {
        if (!isUsageAgreed || !isPrivacyAgreed || !isPrivacyThirdPartyAgreed) {
            setIsAllAgreed(false)
        } else {
            setIsAllAgreed(true)
        }
    }, [isUsageAgreed, isPrivacyAgreed, isPrivacyThirdPartyAgreed])

    useEffect(() => {
        let paidFacilityTotalPrice = 0;
        paidFacilityResult.forEach((paidFacility: any) => {
            paidFacilityTotalPrice += paidFacility.price * paidFacility.count;
        })
        setPaidFacilityTotalPrice(paidFacilityTotalPrice)
    }, [paidFacilityResult])

    useEffect(() => {
        if (paidFacilityTotalPrice + price.total > 0) {
            setTotalPrice(paidFacilityTotalPrice + price.total);
        }
    }, [paidFacilityTotalPrice])

    useEffect(() => {
        if (form) {
            form.setFieldsValue({name, phoneNumber, birthday})
        }
    }, [name, phoneNumber, birthday])

    if (!reservationParam) {
        modal.error({content: '잘못된 접근입니다!', okText: '확인', onOk: () => NavigationService.moveToMain()})
    }

    const [form] = Form.useForm();
    const nameInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const birthdayInputRef = useRef(null);

    const handleInputErrors = (errorFields: any[]) => {
        if (errorFields.length > 0) {
            const firstErrorField = errorFields[0].name[0];
            if (firstErrorField === 'name') {
                // @ts-ignore
                nameInputRef?.current?.focus();
            } else if (firstErrorField === 'phoneNumber') {
                // @ts-ignore
                phoneNumberInputRef?.current?.focus();
            } else if (firstErrorField === 'birthday') {
                // @ts-ignore
                birthdayInputRef?.current?.focus();
            }
        }
    }

    const ErrorMessage = ({message}: { message: string }) => {
        return (
            <Typography.Text style={{
                ...TextStyle,
                marginLeft: 22,
                fontSize: 14,
                fontWeight: '700',
                color: Color.r40
            }}>{message}</Typography.Text>
        )
    }

    const validateForms = () => {
        const agreementPositionY = 1000
        const title = '[필수] 약관 동의'

        if (!isUsageAgreed) {
            modal.warning({
                title,
                content: '객실 이용 및 취소/환불 약관에 동의해 주세요.',
                okText: '확인',
                onOk: () => NavigationService.scrollTo(0, agreementPositionY)
            })
            return false
        }

        if (!isPrivacyAgreed) {
            modal.warning({
                title,
                content: '개인정보 수집 및 이용 약관에 동의해 주세요.',
                okText: '확인',
                onOk: () => NavigationService.scrollTo(0, agreementPositionY + 30)
            })
            return false
        }

        if (!isPrivacyThirdPartyAgreed) {
            modal.warning({
                title,
                content: '개인정보 제3자 제공 약관에 동의해주세요.',
                okText: '확인',
                onOk: () => NavigationService.scrollTo(0, agreementPositionY + 60)
            })
            return false
        }

        return true
    }

    const requestReservation = async () => {
        if (!validateForms()) {
            return
        }

        // @ts-ignore
        const {success, data, error} = await dispatch(createReservation({
            accommodationId: reservationParam.accommodationId,
            roomId: reservationParam.roomId,
            name,
            phoneNumber,
            birthday,
            type: 'BANK_ACCOUNT',
            totalPrice,
            roomPrice: price.days,
            additionalCustomerPrice: price.people,
            options: paidFacilityResult.map(p => ({optionName: p.title, optionPrice: p.price, count: p.count})),
            adultCount: reservationParam?.dateAndCustomerInfo.adult,
            childCount: reservationParam?.dateAndCustomerInfo.child,
            babyCount: reservationParam?.dateAndCustomerInfo.baby,
            comment,
            startDate: convertDateFormat(reservationParam?.dateAndCustomerInfo?.startDateString, 'YYYY-MM-DD'),
            endDate: convertDateFormat(reservationParam?.dateAndCustomerInfo?.endDateString, 'YYYY-MM-DD'),
            agreedUsageTerm: isUsageAgreed,
            agreedPrivacyTerm: isPrivacyAgreed,
            agreedPrivacyThirdPartyTerm: isPrivacyThirdPartyAgreed,
        }))

        if (success) {
            modal.success({
                title: '예약 성공',
                content: '예약에 성공했습니다. 다음 페이지에서 결제를 완료해주세요.',
                okText: '확인',
                onOk: () => navigate(`${PageRoutes.ReservationCheckScreen}?name=${name}&phoneNumber=${phoneNumber}&code=${data.reservationCode}`)
            })
        } else {
            modal.error({
                title: error.title,
                content: error.message
            })
        }
    }

    return (
        <ScreenOuter headerFixed headerType={'back'} headerTitle={'예약하기'} footerBottom={96}>
            <Space style={{paddingLeft: 20, paddingRight: 20, marginTop: 40}}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 26,
                    fontWeight: 700
                }}>{reservationParam?.accommodationName}</Typography.Text>
            </Space>
            <Space style={{paddingLeft: 20, paddingRight: 20, marginTop: 48}}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 700
                }}>🏡 예약 정보</Typography.Text>
            </Space>
            <Space style={{
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 24,
                alignItems: 'flex-start',
                justifyContent: 'space-between'
            }}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 14,
                    fontWeight: 500
                }}>객실</Typography.Text>
                <Space direction={'vertical'} style={{rowGap: 0, alignItems: 'flex-end'}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400
                    }}>{roomName}</Typography.Text>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 12,
                        fontWeight: 400,
                        color: Color.darkTertiary,
                    }}>기준 {roomStandardNumberOfPeople}인, 최대 {roomMaxNumberOfPeople}인</Typography.Text>
                </Space>
            </Space>
            <Space style={{
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 16,
                alignItems: 'flex-start',
                justifyContent: 'space-between'
            }}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 14,
                    fontWeight: 500
                }}>숙박기간</Typography.Text>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 14,
                    fontWeight: 400
                }}>{reservationParam?.dateAndCustomerInfo?.startDateString} ~ {reservationParam?.dateAndCustomerInfo?.endDateString} / {calculateStayDuration(reservationParam?.dateAndCustomerInfo?.startDateString, reservationParam?.dateAndCustomerInfo?.endDateString)}</Typography.Text>
            </Space>
            <Space style={{
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 16,
                alignItems: 'flex-start',
                justifyContent: 'space-between'
            }}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 14,
                    fontWeight: 500
                }}>숙박인원</Typography.Text>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 14,
                    fontWeight: 400
                }}>{reservationParam?.dateAndCustomerInfo?.adult + reservationParam?.dateAndCustomerInfo?.child + reservationParam?.dateAndCustomerInfo?.baby}인
                    (성인 {reservationParam?.dateAndCustomerInfo?.adult},
                    아동 {reservationParam?.dateAndCustomerInfo?.child},
                    유아 {reservationParam?.dateAndCustomerInfo?.baby})</Typography.Text>
            </Space>
            {roomPaidFacilities && roomPaidFacilities.length > 0 && (
                <PaidFacilitySelector paidFacilities={roomPaidFacilities}
                                      onChange={paidFacilityResult => setPaidFacilityResult(paidFacilityResult)}/>
            )}
            <Space style={{paddingLeft: 20, paddingRight: 20, marginTop: 48}}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 700
                }}>💁🏻‍ 예약자 정보</Typography.Text>
            </Space>
            <Form
                form={form}
                style={{width: width}}
                onFinishFailed={(errorInfo) => handleInputErrors(errorInfo.errorFields)}
                onFinish={requestReservation}
            >
                <Form.Item name={"name"} style={{width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'예약자명을 입력해주세요.'}/>}]}>
                    {/*InputLabel 만 사용하면 한글이 깨져서 입력되는 현상. input 태그가 있으면 정상 동작. 원인 파악 필요*/}
                    <input style={{display: 'none'}}/>
                    <InputLabel inputRef={nameInputRef}
                                inputWidth={width}
                                requiredField
                                maxLength={10}
                                minLength={2}
                                label={'예약자명'}
                                placeholder={'홍길동'}
                                value={name}
                                onChangeValue={(v) => setName(removeNumbersAndSpecialCharacters(v))}/>
                </Form.Item>
                <Form.Item name={"phoneNumber"} style={{width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'휴대폰번호를 입력해주세요.'}/>}]}>
                    <InputLabel inputRef={phoneNumberInputRef} requiredField label={'휴대폰번호'} inputWidth={width}
                                minLength={11} maxLength={13}
                                placeholder={'숫자만 입력'}
                                value={phoneNumber}
                                onChangeValue={(v) => setPhoneNumber(formatPhoneNumber(v))}/>
                </Form.Item>
                <Form.Item name={"birthday"} style={{width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'생년월일을 입력해주세요.'}/>}]}>
                    <InputLabel inputRef={birthdayInputRef} requiredField label={'생년월일'} inputWidth={width}
                                type={'text'} maxLength={6}
                                minLength={6}
                                placeholder={'예시. 930907'} value={birthday}
                                onChangeValue={(v) => setBirthday(onlyNumbers(v))}/>
                </Form.Item>
                <Space
                    direction={'vertical'}
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 16,
                    }}
                >
                    <Typography.Text
                        style={{...TextStyle, fontSize: 14, fontWeight: 500}}>요청사항</Typography.Text>
                    <TextArea
                        style={{
                            borderRadius: 16,
                            borderColor: Color.black11,
                            width: width,
                            minHeight: 96,
                            fontSize: 16,
                            fontWeight: 400
                        }}
                        placeholder={'요청사항이 있나요?'}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Space>

                <Space direction={'vertical'} style={{paddingLeft: 20, paddingRight: 20, marginTop: 48}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 18,
                        fontWeight: 700
                    }}>🖋 약관 동의</Typography.Text>
                    <Space
                        className={'clickable'}
                        style={{
                            marginTop: 24,
                            padding: 20,
                            width: width,
                            height: 64,
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            backgroundColor: Color.b1,
                            border: '1px solid var(--Black-b_5, #F1F1F3)',
                            borderRadius: 20,
                        }}
                        onClick={() => {
                            setIsUsageAgreed(!isAllAgreed)
                            setIsPrivacyAgreed(!isAllAgreed)
                            setIsPrivacyThirdPartyAgreed(!isAllAgreed)
                        }}
                    >
                        <Image preview={false} style={{width: 24, height: 24}}
                               src={isAllAgreed ? CheckIcon : CheckIconDisabled}/>
                        {isAllAgreed ? (<TextLiner enabled textStyle={{...TextStyle, fontSize: 16, fontWeight: 700}}
                                                   value={'전체 동의하기'}/>) :
                            (<Typography.Text
                                style={{...TextStyle, fontSize: 16, fontWeight: 700, color: Color.darkTertiary}}>전체
                                동의하기</Typography.Text>)}
                    </Space>
                    <Space
                        className={'clickable'}
                        style={{
                            padding: 20,
                            width: width,
                            height: 64,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: 20,
                        }}
                        onClick={() => setIsUsageAgreed(!isUsageAgreed)}
                    >
                        <Space>
                            <Image preview={false} style={{width: 24, height: 24}}
                                   src={isUsageAgreed ? CheckIcon : CheckIconDisabled}/>
                            {isUsageAgreed ? (
                                <TextLiner enabled textStyle={{...TextStyle, fontSize: 14, fontWeight: 400}}
                                           value={'객실 이용 및 취소/환불 정책 동의 (필수)'}/>) : (
                                <Typography.Text
                                    style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkTertiary}}>
                                    객실 이용 및 취소/환불 정책 동의 (필수)
                                </Typography.Text>
                            )}
                        </Space>
                        <RightOutlined onClick={() => alert('1 click')}/>
                    </Space>
                    <Space
                        className={'clickable'}
                        style={{
                            padding: 20,
                            width: width,
                            height: 64,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: 20,
                        }}
                        onClick={() => setIsPrivacyAgreed(!isPrivacyAgreed)}
                    >
                        <Space>
                            <Image preview={false} style={{width: 24, height: 24}}
                                   src={isPrivacyAgreed ? CheckIcon : CheckIconDisabled}/>
                            {isPrivacyAgreed ? (
                                <TextLiner enabled textStyle={{...TextStyle, fontSize: 14, fontWeight: 400}}
                                           value={'개인정보 수집 및 이용 (필수)'}/>) : (
                                <Typography.Text
                                    style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkTertiary}}>
                                    개인정보 수집 및 이용 (필수)
                                </Typography.Text>
                            )}
                        </Space>
                        <RightOutlined onClick={() => alert('2 click')}/>
                    </Space>
                    <Space
                        className={'clickable'}
                        style={{
                            padding: 20,
                            width: width,
                            height: 64,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: 20,
                        }}
                        onClick={() => setIsPrivacyThirdPartyAgreed(!isPrivacyThirdPartyAgreed)}
                    >
                        <Space>
                            <Image preview={false} style={{width: 24, height: 24}}
                                   src={isPrivacyThirdPartyAgreed ? CheckIcon : CheckIconDisabled}/>
                            {isPrivacyThirdPartyAgreed ? (
                                <TextLiner enabled textStyle={{...TextStyle, fontSize: 14, fontWeight: 400}}
                                           value={'개인정보 제3자 제공 동의 (필수)'}/>) : (
                                <Typography.Text
                                    style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkTertiary}}>
                                    개인정보 제3자 제공 동의 (필수)
                                </Typography.Text>
                            )}
                        </Space>
                        <RightOutlined onClick={() => alert('3 click')}/>
                    </Space>
                </Space>

                <Space style={{paddingLeft: 20, paddingRight: 20, marginTop: 48}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 18,
                        fontWeight: 700
                    }}>💳 결제 정보</Typography.Text>
                </Space>

                <Space style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Space style={{marginTop: 24, width: width / 2}}>
                        <Image preview={false} src={YellowSelectIcon} style={{width: 24, height: 24}}/>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500}}>무통장 입금</Typography.Text>
                    </Space>

                    <Space className={'clickable'} style={{marginTop: 24, width: width / 2}}
                           onClick={() => message.info({content: `'신용/체크카드' 결제 기능은 준비 중입니다.`})}>
                        <Image preview={false} src={SelectDisabledIcon} style={{width: 24, height: 24}}/>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 500,
                            color: Color.darkDisabled
                        }}>신용/체크카드</Typography.Text>
                    </Space>
                </Space>

                <Space
                    direction={'vertical'}
                    style={{
                        marginTop: 24,
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <Space direction={'vertical'} style={{
                        rowGap: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: width,
                        height: 98,
                        backgroundColor: '#303036',
                        borderRadius: 16
                    }}>
                        <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 500, color: Color.y40}}>총 이용
                            요금</Typography.Text>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 18,
                            fontWeight: 500,
                            color: Color.y40
                        }}>{moneyValue(totalPrice)}원</Typography.Text>
                    </Space>

                    <Space direction={'vertical'} style={{marginTop: 24, width: width}}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 700}}>요금 상세
                            내역</Typography.Text>

                        <Space style={{
                            marginTop: 16,
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between'
                        }}>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500
                            }}>객실 요금</Typography.Text>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500
                            }}>{moneyValue(price.total)}원</Typography.Text>
                        </Space>

                        <Space style={{
                            marginTop: 16,
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between'
                        }}>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500,
                                color: Color.darkTertiary,
                            }}>숙박</Typography.Text>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500,
                                color: Color.darkTertiary,
                            }}>{moneyValue(price.days)}원</Typography.Text>
                        </Space>

                        {price.people > 0 && (
                            <Space style={{
                                marginTop: 16,
                                width: '100%',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between'
                            }}>
                                <Typography.Text style={{
                                    ...TextStyle,
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: Color.darkTertiary,
                                }}>추가 인원</Typography.Text>
                                <Typography.Text style={{
                                    ...TextStyle,
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: Color.darkTertiary,
                                }}>{moneyValue(price.people)}원</Typography.Text>
                            </Space>
                        )}

                        <Space style={{
                            marginTop: 32,
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between'
                        }}>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500
                            }}>유료 시설 요금</Typography.Text>
                            <Typography.Text style={{
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500
                            }}>{moneyValue(paidFacilityTotalPrice)}원</Typography.Text>
                        </Space>
                        {paidFacilityTotalPrice > 0 && (
                            paidFacilityResult
                                .filter(paidFacility => paidFacility.count > 0)
                                .map((paidFacility, index) => {
                                    return (
                                        <Space key={index} style={{
                                            marginTop: 16,
                                            width: '100%',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Typography.Text style={{
                                                ...TextStyle,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: Color.darkTertiary,
                                            }}>{paidFacility.title} x {paidFacility.count}</Typography.Text>
                                            <Typography.Text style={{
                                                ...TextStyle,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: Color.darkTertiary,
                                            }}>{moneyValue(paidFacility.price * paidFacility.count)}원</Typography.Text>
                                        </Space>
                                    )
                                })
                        )}
                    </Space>
                </Space>
                <Form.Item name={"button"}>
                    <Space style={{
                        ...MobileSizeStyle,
                        position: 'fixed',
                        bottom: 0,
                        width: deviceWidth,
                        height: 96,
                        paddingTop: 16,
                        paddingBottom: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                        alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'rgba(252, 252, 253, 0.90)',
                    }}>
                        <Space direction={'vertical'} style={{rowGap: 0}}>
                            <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 700}}>
                                총 {moneyValue(totalPrice)}원
                            </Typography.Text>
                        </Space>
                        <Button
                            htmlType={"submit"}
                            style={{
                                width: 139,
                                height: 64,
                                borderRadius: 40,
                                border: '1px solid var(--Black-b_5, #FDDF35)',
                                backgroundColor: Color.y40, ...TextStyle,
                                fontSize: 16,
                                fontWeight: 700,
                            }}
                        >예약하기</Button>
                    </Space>
                </Form.Item>
            </Form>
            {contextHolder}
        </ScreenOuter>
    )
}

const PaidFacilitySelector = ({paidFacilities = [], onChange}: {
    paidFacilities: any[],
    onChange: (paidFacilityResult: any[]) => void
}) => {
    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth - 40 : 372

    const initialPaidFacilitySelectorMap: any = {}
    for (let i = 0; i < paidFacilities.length; i++) {
        initialPaidFacilitySelectorMap[paidFacilities[i].name] = {
            price: paidFacilities[i].price,
            count: 0,
        }
    }

    const [paidFacilitySelectorMap, setPaidFacilitySelectorMap] = useState(initialPaidFacilitySelectorMap)

    useEffect(() => {
        onChange(Object.keys(paidFacilitySelectorMap)
            .map(key => ({title: key, ...paidFacilitySelectorMap[key]})))
    }, [paidFacilitySelectorMap])

    return (
        <Space direction={'vertical'} style={{
            marginTop: 40,
            padding: 20,
            borderRadius: 20,
            width: width,
            alignSelf: 'center',
            backgroundColor: Color.b1,
            borderColor: Color.black5,
            border: '1px solid var(--Black-b_5, #F1F1F3)',
        }}>
            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 700}}>유료시설추가</Typography.Text>
            {paidFacilities?.map((paidFacility, index) => {
                if (!paidFacilitySelectorMap[paidFacility.name]) {
                    return null
                }

                return (
                    <Space key={index} direction={'vertical'} style={{marginTop: 24}}>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 500
                        }}>{paidFacility?.name} / {moneyValue(paidFacility?.price)}원</Typography.Text>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 12,
                            fontWeight: 400,
                            color: Color.darkSecondary,
                        }}>{paidFacility?.description}</Typography.Text>
                        <NumberIncrement
                            initialValue={0}
                            disabled={paidFacility.isMultiple ? false : paidFacilitySelectorMap[paidFacility.name].count >= 1}
                            onChange={value => {
                                const newPaidFacilitySelectorMap = {...paidFacilitySelectorMap}
                                newPaidFacilitySelectorMap[paidFacility.name].count = value;
                                setPaidFacilitySelectorMap(newPaidFacilitySelectorMap)
                            }}
                        />
                    </Space>
                )
            })}
        </Space>
    )
}

const InputLabel = ({
                        inputRef,
                        requiredField,
                        type,
                        inputWidth = 100,
                        maxLength = 20,
                        minLength = 0,
                        label,
                        placeholder,
                        value,
                        onChangeValue
                    }: {
    inputRef?: any,
    requiredField?: boolean,
    type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week',
    inputWidth?: number,
    maxLength?: number,
    minLength?: number
    label: string,
    placeholder: string,
    value: string,
    onChangeValue: (v: string) => void,
}) => {
    return (
        <Space
            direction={'vertical'}
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 16,
            }}
        >
            <Typography.Text
                style={{...TextStyle, fontSize: 14, fontWeight: 500}}>{label}
                {requiredField && (<RequiredFieldMark/>)}</Typography.Text>
            <Input
                ref={inputRef || null}
                type={type || ''}
                maxLength={maxLength}
                minLength={requiredField ? minLength : 0}
                style={{
                    borderRadius: 16,
                    borderColor: Color.black11,
                    width: inputWidth,
                    height: 50, ...TextStyle,
                    fontSize: 16,
                    fontWeight: 400
                }}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChangeValue(e?.target?.value)}
            />
        </Space>
    )
}
