import {Map} from 'immutable';
import {SET_IS_LOADING_ROOM, setIsLoading} from "../Loading";
import RoomApi from "../../apis/RoomApi";

// action types
const SAVE_ROOM = 'Room/SAVE_ROOM'

// action creators
export const saveRoom = (room: any) => ({
    type: SAVE_ROOM,
    payload: {
        room,
    }
})

// initialState
const initialState = Map({
    id: null,
    name: null,
    standardNumberOfPeople: null,
    maxNumberOfPeople: null,
    roomSize: null,
    numberOfLivingRoom: null,
    numberOfRoom: null,
    numberOfBathroom: null,
    price: null,
    additionalFeeAdult: null,
    additionalFeeChild: null,
    additionalFeeBaby: null,
    contents: [],
    basicFacilities: [],
    paidFacilities: [],
});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SAVE_ROOM:
            return state.set('id', action.payload.room.id)
                .set('name', action.payload.room.name)
                .set('standardNumberOfPeople', action.payload.room.standardNumberOfPeople)
                .set('maxNumberOfPeople', action.payload.room.maxNumberOfPeople)
                .set('roomSize', action.payload.room.roomSize)
                .set('numberOfLivingRoom', action.payload.room.numberOfLivingRoom)
                .set('numberOfRoom', action.payload.room.numberOfRoom)
                .set('numberOfBathroom', action.payload.room.numberOfBathroom)
                .set('price', action.payload.room.price)
                .set('additionalFeeAdult', action.payload.room.additionalFeeAdult)
                .set('additionalFeeChild', action.payload.room.additionalFeeChild)
                .set('additionalFeeBaby', action.payload.room.additionalFeeBaby)
                .set('contents', action.payload.room.contents)
                .set('basicFacilities', action.payload.room.basicFacilities)
                .set('paidFacilities', action.payload.room.paidFacilities)
        default:
            return state;
    }
};

export const requestRoom = (roomId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.get(roomId);

        if (response.ok) {
            dispatch(saveRoom(json.data || {}))
        }

        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
        // TODO: 에러처리
    }
};
