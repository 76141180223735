import {Map} from 'immutable';
import AccommodationApi from "../../apis/AccommodationApi";
import {SET_IS_LOADING_ACCOMMODATION, SET_IS_LOADING_ROOM, setIsLoading} from "../Loading";
import dayjs from "dayjs";

// action types
const SAVE_ACCOMMODATION = 'Accommodation/SAVE_ACCOMMODATION'
const SAVE_ACCOMMODATION_CONTENTS = 'Accommodation/SAVE_ACCOMMODATION_CONTENTS'
const SAVE_ROOMS = 'Accommodation/SAVE_ROOMS'
const SET_IS_ACCOMMODATION_NOT_FOUND = 'Accommodation/SET_IS_ACCOMMODATION_NOT_FOUND'

// action creators
const saveAccommodation = (accommodation: any) => ({
    type: SAVE_ACCOMMODATION,
    payload: {
        accommodation,
    },
})

const saveAccommodationContents = (contents: any) => ({
    type: SAVE_ACCOMMODATION_CONTENTS,
    payload: {
        contents,
    }
})

const saveRooms = (rooms: any) => ({
    type: SAVE_ROOMS,
    payload: {
        rooms,
    }
})

export const setIsAccommodationNotFound = (isAccommodationNotFound: boolean) => ({
    type: SET_IS_ACCOMMODATION_NOT_FOUND,
    payload: {
        isAccommodationNotFound,
    }
})

// initialState
const initialState = Map({
    id: null,
    status: null,
    name: null,
    officePhoneNumber: null,
    description: null,
    checkInTime: null,
    checkOutTime: null,
    address: null,
    addressDetail: null,
    latitude: null,
    longitude: null,
    usagePolicy: null,
    refundPolicy: null,
    refundPercentages: [],
    contents: [],
    isAccommodationNotFound: false,
    rooms: [], // 예약하기 화면의 하단 객실 리스트
});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SAVE_ACCOMMODATION:
            return state.set('id', action.payload.accommodation.id)
                .set('status', action.payload.accommodation.status)
                .set('name', action.payload.accommodation.name)
                .set('description', action.payload.accommodation.description)
                .set('checkInTime', action.payload.accommodation.checkInTime)
                .set('checkOutTime', action.payload.accommodation.checkOutTime)
                .set('officePhoneNumber', action.payload.accommodation.officePhoneNumber)
                .set('address', action.payload.accommodation.address)
                .set('addressDetail', action.payload.accommodation.addressDetail)
                .set('latitude', action.payload.accommodation.latitude)
                .set('longitude', action.payload.accommodation.longitude)
                .set('usagePolicy', action.payload.accommodation.usagePolicy)
                .set('refundPolicy', action.payload.accommodation.refundPolicy?.policy || null)
                .set('refundPercentages', action.payload.accommodation.refundPolicy?.percentages || [])
                .set('contents', action.payload.accommodation.contents || [])
        case SAVE_ACCOMMODATION_CONTENTS:
            return state.set('contents', action.payload.contents)
        case SAVE_ROOMS:
            return state.set('rooms', action.payload.rooms)
        case SET_IS_ACCOMMODATION_NOT_FOUND:
            return state.set('isAccommodationNotFound', action.payload.isAccommodationNotFound)
        default:
            return state;
    }
};

export const requestAccommodation = (accommodationId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.get(accommodationId);

        if (response.ok) {
            dispatch(saveAccommodation(json.data || {}))
        } else {
            dispatch(setIsAccommodationNotFound(true));
        }

        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
        // TODO: 에러처리
    }
};

export const requestRooms = (accommodationId: number, startDate: string, endDate: string, adult: number = 2, child: number = 0, baby: number = 0) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const startDateParam = dayjs(startDate, 'YY.MM.DD (ddd)').format('YYYY-MM-DDTHH:mm:ss')
        const endDateParam = dayjs(endDate, 'YY.MM.DD (ddd)').format('YYYY-MM-DDTHH:mm:ss')
        const {
            response,
            json
        } = await AccommodationApi.getRooms(accommodationId, startDateParam, endDateParam, adult, child, baby);

        if (response.ok) {
            dispatch(saveRooms(json.data || {}))
        } else {
            dispatch(setIsAccommodationNotFound(true))
        }

        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
        // TODO: 에러처리
    }
}
