import {Col, Layout, Row, Typography} from "antd";
import React from "react";
import {Content, Footer} from "antd/es/layout/layout";
import {Color, MobileSizeStyle, TextStyle} from "../../constants/style";
import EmptySpace from "../EmptySpace";
import {useLocation} from "react-router-dom";

const ScreenGrid = ({...props}) => {
    return (
        <Layout style={{minHeight: '100vh'}}>
            <Content style={{
                display: 'flex',
                backgroundColor: Color.white,
                justifyContent: 'center',
            }}>
                <div style={{
                    flexDirection: 'column',
                    display: 'flex',
                    width: '100%',
                    backgroundColor: Color.white,
                    ...MobileSizeStyle,
                }}>
                    <div style={{flex: 1}}>
                        {props.children}
                    </div>
                    <CustomFooter bottom={props.footerBottom}/>
                </div>
            </Content>
        </Layout>
    )
}

const CustomFooter = ({bottom = 0}: {bottom?: number}) => (
    <Footer style={{
        width: '100%',
        height: 'auto',
        marginTop: 40,
        marginBottom: bottom,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        backgroundColor: Color.lightGrey,
        ...MobileSizeStyle,
    }}>
        <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
            주식회사 데이스프링은 숙박예약서비스를 제공하는 통신판매중개자이며 숙소
            정보, 이용 및 환불 등과 관련된 책임은 숙박 업체에게 있습니다.
        </Typography.Text>
        <EmptySpace color={Color.lightGrey}/>
        <Typography.Text
            style={{...TextStyle, fontSize: 12, fontWeight: 500, color: Color.darkTertiary, marginTop: 20}}>
            서비스 제공 : 주식회사 데이스프링
        </Typography.Text>
    </Footer>
)

export default ScreenGrid;
