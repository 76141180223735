import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {Calendar, Modal, Select, Space, Typography} from "antd";
import {Color, MobileSizeStyle, TextStyle} from "../../../constants/style";
import ko from "antd/es/date-picker/locale/ko_KR";
import TextLiner from "../../../components/TextLiner";
import NoBorderButton from "../../../components/NoBorderButton";
import {calculateStayDuration} from "../util";

const {Option} = Select;
export const DateModal = ({isOpenModal, onOk, onCancel}: {
    isOpenModal: boolean,
    onOk: (startDateString: string, endDateString: string) => void,
    onCancel: () => void,
}) => {
    const now = dayjs()
    const [selectedDates, setSelectedDates] = useState<any>([]);
    const [currentDate, setCurrentDate] = useState(dayjs(now.format('YYYY-MM-DD')));
    const [startDateString, setStartDateString] = useState('')
    const [endDateString, setEndDateString] = useState('')

    const validRangeDay = 90 // TODO: API 추가
    const maxReservationDay = 14 // TODO: API 추가

    const initialValidRangeStartDate = now.startOf('day')
    const initialValidRangeEndDate = now.add(validRangeDay, 'day')
    const [validRangeStartDate, setValidRangeStartDate] = useState(initialValidRangeStartDate)
    const [validRangeEndDate, setValidRangeEndDate] = useState(initialValidRangeEndDate)

    useEffect(() => {
        if (selectedDates && selectedDates.length >= 2) {
            setStartDateString(selectedDates[0].format('YY.MM.DD (ddd)'))
            setEndDateString(selectedDates[1].format('YY.MM.DD (ddd)'))
        } else if (selectedDates && selectedDates.length >= 1) {
            setStartDateString(selectedDates[0].format('YY.MM.DD (ddd)'))
            setEndDateString('')

            const startDate = selectedDates[0].subtract(maxReservationDay, 'day')
            setValidRangeStartDate(startDate.isBefore(now) ? initialValidRangeStartDate : startDate)
            setValidRangeEndDate(selectedDates[0].add(maxReservationDay, 'day'))
        } else {
            setStartDateString('')
            setEndDateString('')

            setValidRangeStartDate(initialValidRangeStartDate)
            setValidRangeEndDate(initialValidRangeEndDate)
        }
    }, [selectedDates])

    const handleMonthChange = (month: any) => {
        const newDate = currentDate.set('month', month);
        setCurrentDate(newDate);
    };

    const handleYearChange = (year: any) => {
        const newDate = currentDate.set('year', year);
        setCurrentDate(newDate);
    };

    const monthOptions: any[] = [];
    for (let i = 0; i < 12; i++) {
        monthOptions.push(
            <Option key={i} value={i}>
                {`${i + 1} 월`}
            </Option>
        );
    }

    const yearOptions: any[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 1; i++) {
        yearOptions.push(
            <Option key={i} value={i}>
                {`${i} 년`}
            </Option>
        );
    }

    const calendarHeader = () => (
        <div style={{display: 'flex', marginBottom: 20, alignItems: 'center'}}>
            <Select
                defaultValue={currentDate.year()}
                value={currentDate.year()}
                style={{width: 100,}}
                onChange={handleYearChange}
            >
                {yearOptions}
            </Select>
            <Select
                defaultValue={currentDate.month()}
                value={currentDate.month()}
                style={{width: 120, marginLeft: 10}}
                onChange={handleMonthChange}
            >
                {monthOptions}
            </Select>
        </div>
    );

    if (!isOpenModal) {
        return null
    }

    const handleDateSelect = (value: any) => {
        const selectedDate = value;
        const [start, end] = selectedDates.map((date: any) => dayjs(date));

        if (!start && !end) {
            setSelectedDates([selectedDate]);
        } else if (start && end) {
            setSelectedDates([selectedDate]);
        } else if (start && !end) {
            if (selectedDate.isBefore(start, 'day')) {
                setSelectedDates([selectedDate, start]); // 순서를 뒤집어서 저장
            } else if (selectedDate.isAfter(start, 'day')) {
                setSelectedDates([start, selectedDate]);
            } else {
                setSelectedDates([])
            }
        }
    };

    const dateCellRender = (value: any) => {
        const isSelected = selectedDates.some((date: any) => date.isSame(value, 'day'));

        if (
            isSelected ||
            (selectedDates.length === 2 &&
                value.isBetween(selectedDates[0], selectedDates[1], 'day', '[]'))
        ) {
            const isFirstDate = value.isSame(selectedDates[0], 'day')
            const isLastDate = value.isSame(selectedDates[1], 'day')
            const borderRadius = 10
            return (
                <div
                    className="blue-range"
                    style={{
                        borderTopLeftRadius: isFirstDate ? borderRadius : 0,
                        borderBottomLeftRadius: isFirstDate ? borderRadius : 0,
                        borderTopRightRadius: isLastDate ? borderRadius : 0,
                        borderBottomRightRadius: isLastDate ? borderRadius : 0,
                    }}
                >
                    {value.date()}
                </div>);
        }

        return <div>{value.date()}</div>;
    };

    return (
        <Modal
            open={true}
            footer={<ModalFooter onOk={() => onOk(startDateString, endDateString)} onCancel={onCancel}/>}
            centered={true}
            wrapClassName={'bottom-center-modal'}
            closable={false}
            width={'100%'}
            style={{
                ...MobileSizeStyle,
                position: 'fixed',
                top: 'auto',
                bottom: -20,
                transform: 'translate(0, 0)',
            }}
        >
            <Space direction={'vertical'}>
                <Typography.Text style={{...TextStyle, fontSize: 20, fontWeight: 700}}>예약 날짜 선택</Typography.Text>
                <div style={{width: '100%', height: 330, marginTop: 20, marginBottom: 20}}>
                    <Calendar
                        locale={ko}
                        fullscreen={false}
                        value={currentDate}
                        validRange={[validRangeStartDate, validRangeEndDate]}
                        onSelect={handleDateSelect}
                        fullCellRender={dateCellRender}
                        headerRender={() => calendarHeader()}
                        onPanelChange={(value: any, mode: string) => {
                            if (mode === 'year') {
                                const updated = currentDate.set('year', value.year())
                                setCurrentDate(updated)
                            } else if (mode === 'month') {
                                let updated = currentDate.set('month', value.month())
                                updated = updated.year(value.year())
                                setCurrentDate(updated)
                            }
                        }}
                    />
                </div>
                {(startDateString || endDateString) && (
                    <Space style={{height: 50}}>
                        <TextLiner enabled textStyle={{...TextStyle, fontSize: 16, fontWeight: 600}}
                                   value={startDateString}/>
                        <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 600}}>~</Typography.Text>
                        <TextLiner enabled textStyle={{...TextStyle, fontSize: 16, fontWeight: 600}}
                                   value={endDateString}/>
                        <Typography.Text
                            style={TextStyle}>{calculateStayDuration(startDateString, endDateString)}</Typography.Text>
                    </Space>
                )}
            </Space>
        </Modal>
    )
}

const ModalFooter = ({onOk, onCancel}: any) => (
    <Space
        className={'border-top'}
        style={{
            width: '100%',
            height: 82,
            alignItems: 'center',
            justifyContent: 'space-evenly'
        }}>
        <NoBorderButton
            style={{
                width: 140,
                height: 50,
                borderRadius: 16,
                backgroundColor: Color.black5, ...TextStyle,
                fontSize: 16,
                fontWeight: 700,
            }}
            value={'닫기'}
            onClick={onCancel}
        />
        <NoBorderButton
            style={{
                width: 140,
                height: 50,
                backgroundColor: Color.y40, ...TextStyle,
                fontSize: 16,
                fontWeight: 700
            }}
            value={'확인'}
            onClick={onOk}
        />
    </Space>
)
