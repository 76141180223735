// @ts-nocheck

import ScreenOuter from "../../components/ScreenOuter";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Divider, Input, Modal, Space, Typography} from "antd";
import useWindowDimensions, {Color, TextStyle} from "../../constants/style";
import RequiredFieldMark from "../../components/RequiredFieldMark";
import {formatPhoneNumber, moneyValue, removeNumbersAndSpecialCharacters} from "../../utils/string";
import {useDispatch} from "react-redux";
import {searchReservation} from "../../redux/Reservation";
import {PageRoutes} from "../../App";
import NavigationService from "../../services/NavigationService";
import dayjs from "dayjs";
import {calculateStayDuration, transformToDaysString, transformToPeopleString} from "../AccommodationScreen/util";
import {InfoCircleFilled, InfoCircleOutlined} from "@ant-design/icons";
import EmptySpace from "../../components/EmptySpace";
import TextLiner from "../../components/TextLiner";

const ReservationCheckScreen = ({}) => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const nameParam = queryParams.get('name')
    const phoneNumberParam = queryParams.get('phoneNumber')
    const reservationCodeParam = queryParams.get('code')

    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth - 40 : 372

    const [status, setStatus] = useState(location?.state?.paymentStatus || null)
    const [reservationCode, setReservationCode] = useState(location?.state?.code || null)
    const [createdDate, setCreatedDate] = useState(location?.state?.createdDate || null)
    const [roomName, setRoomName] = useState(location?.state?.roomName || null)
    const [standardNumberOfPeople, setStandardNumberOfPeople] = useState(location?.state?.standardNumberOfPeople || 0)
    const [maxNumberOfPeople, setMaxNumberOfPeople] = useState(location?.state?.maxNumberOfPeople || 0)
    const [startDate, setStartDate] = useState(location?.state?.startDate || null)
    const [endDate, setEndDate] = useState(location?.state?.endDate || null)
    const [adultCount, setAdultCount] = useState(location?.state?.adultCount || 0)
    const [childCount, setChildCount] = useState(location?.state?.childCount || 0)
    const [babyCount, setBabyCount] = useState(location?.state?.babyCount || 0)
    const [name, setName] = useState(location?.state?.name || null)
    const [phoneNumber, setPhoneNumber] = useState(location?.state?.phoneNumber || null)
    const [comment, setComment] = useState(location?.state?.comment || null)
    const [paymentType, setPaymentType] = useState(location?.state?.paymentType || null)
    const [paidExpiredDate, setPaidExpiredDate] = useState(location?.state?.paidExpiredDate || null)
    const [bankName, setBankName] = useState(location?.state?.bankName || null)
    const [accountNumber, setAccountNumber] = useState(location?.state?.accountNumber || null)
    const [accountHolder, setAccountHolder] = useState(location?.state?.accountHolder || null)
    const [totalPrice, setTotalPrice] = useState(location?.state?.totalPrice || null)
    const [roomPrice, setRoomPrice] = useState(location?.state?.roomPrice || null)
    const [additionalCustomerPrice, setAdditionalCustomerPrice] = useState(location?.state?.additionalCustomerPrice || 0)
    const [totalOptionPrice, setTotalOptionPrice] = useState(location?.state?.totalOptionPrice || 0)
    const [options, setOptions] = useState(location?.state?.options || [])


    useEffect(() => {
        NavigationService.scrollToTop()

        if (!!(nameParam && phoneNumberParam && reservationCodeParam)) {
            requestSearchReservation()
        }
    }, [])

    const requestSearchReservation = async () => {
        const {
            success,
            data,
            error
            // @ts-ignore
        } = await dispatch(searchReservation(nameParam, phoneNumberParam, reservationCodeParam))

        if (success) {
            setStatus(data?.reservation?.paymentStatus)
            setReservationCode(data?.reservation?.code)
            setCreatedDate(data?.reservation?.createdDate)
            setRoomName(data?.reservation?.roomName)
            setStandardNumberOfPeople(data?.reservation?.standardNumberOfPeople)
            setMaxNumberOfPeople(data?.reservation?.maxNumberOfPeople)
            setStartDate(data?.reservation?.startDate)
            setEndDate(data?.reservation?.endDate)
            setAdultCount(data?.reservation?.adultCount)
            setChildCount(data?.reservation?.childCount)
            setBabyCount(data?.reservation?.babyCount)
            setName(data?.reservation?.name)
            setPhoneNumber(data?.reservation?.phoneNumber)
            setComment(data?.reservation?.comment)
            setPaymentType(data?.reservation?.paymentType)
            setPaidExpiredDate(data?.reservation?.paidExpiredDate)
            setBankName(data?.reservation?.bankName)
            setAccountNumber(data?.reservation?.accountNumber)
            setAccountHolder(data?.reservation?.accountHolder)
            setTotalPrice(data?.reservation?.totalPrice)
            setRoomPrice(data?.reservation?.roomPrice)
            setAdditionalCustomerPrice(data?.reservation?.additionalCustomerPrice)
            setTotalOptionPrice(data?.reservation?.totalOptionPrice)
            setOptions(data?.reservation?.options)
        } else {
            modal.error({
                title: error.title,
                content: error.message,
                okText: '확인',
                onOk: () => NavigationService.moveToMain()
            })
        }
    }

    const numberOfPeopleString = `기준 ${standardNumberOfPeople}인, 최대 ${maxNumberOfPeople}인`
    const daysString = transformToDaysString(startDate, endDate)
    const peopleString = transformToPeopleString(adultCount, childCount, babyCount)
    const paymentInfoString = `${paymentType} / ${accountNumber} ${bankName} (${accountHolder})`

    return (
        <ScreenOuter headerType={'back'} headerTitle={''}>
            <Space direction={'vertical'} style={{marginTop: 30, paddingLeft: 20, paddingRight: 20}}>
                <Typography.Text style={{...TextStyle, fontSize: 26, fontWeight: 700}}>
                    예약 확인
                </Typography.Text>
            </Space>

            <Space direction={'vertical'} style={{marginTop: 60, paddingLeft: 20, paddingRight: 20}}>

                <LabelText label={'예약 상태'} text={status} textLinerEnabled textColor={Color.b40} textFontWeight={700}/>
                <LabelText label={'예약 번호'} text={reservationCode}/>
                <LabelText label={'예약 일시'} text={dayjs(createdDate).format('YYYY-MM-DD HH:mm:ss')}/>

                <Divider style={{marginTop: 30}}/>

                <Space style={{marginBottom: 20}}>
                    <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 700}}>🏡 예약 정보</Typography.Text>
                </Space>

                <LabelText label={'객실'} text={roomName} subText={numberOfPeopleString}/>
                <LabelText label={'숙박 기간'} text={daysString}/>
                <LabelText label={'숙박 인원'} text={peopleString}/>

                <Divider style={{marginTop: 30}}/>

                <Space style={{marginBottom: 20}}>
                    <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 700}}>💁🏻‍ 예약자 정보</Typography.Text>
                </Space>

                <LabelText label={'예약자'} text={name}/>
                <LabelText label={'휴대폰번호'} text={phoneNumber}/>
                <Space
                    direction={'vertical'}
                    style={{marginTop: 10, width: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500}}>
                        요청사항
                    </Typography.Text>
                    <Space style={{
                        marginTop: 5,
                        width: width,
                        height: 'auto',
                        borderRadius: 16,
                        border: '1px solid var(--Black-b_5, #F1F1F3)',
                        padding: 20,
                        backgroundColor: Color.b1,
                    }}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400}}>
                            {comment}
                        </Typography.Text>
                    </Space>
                </Space>

                <Divider style={{marginTop: 30}}/>

                <Space style={{marginBottom: 20}}>
                    <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 700}}>💳 결제 정보</Typography.Text>
                </Space>

                <Space direction={'vertical'} style={{width: '100%'}}>
                    <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400}}>
                        {paymentInfoString}
                    </Typography.Text>

                    <Space
                        direction={'vertical'}
                        style={{
                            marginTop: 10,
                            rowGap: 0,
                            padding: 16,
                            width: '100%',
                            height: 'auto',
                            borderRadius: 16,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            backgroundColor: Color.b3
                        }}>
                        <Space style={{marginBottom: 15, alignItems: 'center', justifyContent: 'space-between'}}>
                            <InfoCircleFilled style={{fontSize: 18, color: Color.b40, marginTop: 5}}/>
                            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 700, color: Color.b40}}>
                                {dayjs(paidExpiredDate).format('YYYY-MM-DD HH:mm:ss')} 까지 입금이 확인되지 않을 경우 자동 취소됩니다.
                            </Typography.Text>
                        </Space>

                        <Space style={{rowGap: 0, alignItems: 'flex-start', justifyContent: 'space-between'}}>
                            <InfoCircleFilled style={{fontSize: 4, color: Color.b40}}/>
                            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.b40}}>
                                입금이 확인되면 예약 완료 문자가 전송됩니다.
                            </Typography.Text>
                        </Space>
                        <Space style={{rowGap: 0, alignItems: 'flex-start', justifyContent: 'space-between'}}>
                            <InfoCircleFilled style={{fontSize: 4, color: Color.b40}}/>
                            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.b40}}>
                                무통장 입금 시 입금 확인을 위해 반드시 예약자명으로 입금해주세요.
                            </Typography.Text>
                        </Space>
                    </Space>

                    <Space
                        direction={'vertical'}
                        style={{
                            marginTop: 20,
                            width: '100%',
                            height: 100,
                            borderRadius: 16,
                            backgroundColor: '#303036',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500, color: Color.y40}}>
                            총 이용 요금
                        </Typography.Text>
                        <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 700, color: Color.y40}}>
                            {moneyValue(totalPrice)}원
                        </Typography.Text>
                    </Space>

                    <Space direction={'vertical'} style={{rowGap: 0, marginTop: 24, width: '100%'}}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 700}}>
                            요금 상세 내역
                        </Typography.Text>

                        <LabelText label={'객실 요금'} text={`${moneyValue(roomPrice)}원`}/>
                        <LabelText label={'숙박'}
                                   labelColor={Color.darkTertiary}
                                   text={`${moneyValue(roomPrice)}원`}
                                   textColor={Color.darkTertiary}/>
                        <LabelText label={'추가 인원'}
                                   labelColor={Color.darkTertiary}
                                   text={`${moneyValue(additionalCustomerPrice)}원`}
                                   textColor={Color.darkTertiary}/>
                        <EmptySpace/>
                        <LabelText label={'유료 시설 요금'}
                                   text={`${moneyValue(totalOptionPrice)}원`}/>

                        {options.map((option, index) => (
                            <LabelText key={index}
                                       label={`${option.optionName} X ${option.count}`}
                                       labelColor={Color.darkTertiary}
                                       text={`${moneyValue(option.optionPrice * option.count)}원`}
                                       textColor={Color.darkTertiary}/>
                        ))}
                    </Space>

                </Space>
            </Space>

            {contextHolder}
        </ScreenOuter>
    )
}

const LabelText = ({
                       label,
                       text,
                       textLinerEnabled = false,
                       labelColor = Color.darkPrimary,
                       subText,
                       textColor = Color.darkPrimary,
                       textFontWeight = 400
                   }) => {
    return (
        <Space
            style={{marginTop: 10, width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500, color: labelColor}}>
                {label}
            </Typography.Text>
            <Space direction={'vertical'} style={{rowGap: 0, textAlign: 'right'}}>
                <TextLiner enabled={textLinerEnabled}
                           textStyle={{...TextStyle, fontSize: 14, fontWeight: textFontWeight, color: textColor}}
                           value={text}/>
                {subText && (
                    <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                        {subText}
                    </Typography.Text>
                )}
            </Space>
        </Space>
    )
}

export default ReservationCheckScreen
