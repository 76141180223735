import ApiService from "../../services/ApiService";
import dayjs from "dayjs";

class AccommodationApi {
    static register(
        name: string,
        address: string,
        addressDetail: string,
        latitude: string,
        longitude: string,
        officePhoneNumber: string,
        bizNumber: string,
        bizRegistrationFilePath: string,
        bankName: string,
        accountNumber: string,
        accountHolder: string) {

        const path = '/accommodations'
        const body = {
            name,
            address,
            addressDetail,
            latitude,
            longitude,
            officePhoneNumber,
            bizNumber,
            bizRegistrationFilePath,
            bankName,
            accountNumber,
            accountHolder,
        }

        return ApiService.post(path, body, true)
    }

    static get(accommodationId: number) {
        const path = `/front/accommodations/${accommodationId}`
        return ApiService.get(path, false)
    }

    static getRooms(accommodationId: number, startDate: string, endDate: string, adult: number, child: number, baby: number) {
        const path = `/front/rooms/search?accommodationId=${accommodationId}&startDate=${startDate}&endDate=${endDate}&adultCount=${adult}&childCount=${child}&babyCount=${baby}`
        return ApiService.get(path, false)
    }
}

export default AccommodationApi
