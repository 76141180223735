import {Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {CloseOutlined} from "@ant-design/icons";
import React from "react";
import EmptySpace from "../../../components/EmptySpace";
import './BasicTypeComponent.css'


export type BasicFacilityType = '주변' | '침실' | '주방' | '욕실' | '냉/난방' | '엔터테인먼트' | '주차' | '기타'

const FacilityLabel = ({label}: {
    label: string,
}) => {
    return (
        <Space className={'inner-basic-type'} style={{
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 6,
            paddingBottom: 6,
            width: 'auto',
            backgroundColor: Color.black3,
            borderColor: Color.black5,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Typography.Text
                style={{...TextStyle, fontSize: 12, fontWeight: 500}}>{label}</Typography.Text>
        </Space>
    )
}

export const FacilityTypeComponent = ({type, labels = []}: { type?: BasicFacilityType, labels: string[] }) => {
    if (labels.length < 1) {
        return null
    }

    return (
        <>
            <EmptySpace size={10}/>
            {type && (
                <Typography.Title level={4}
                                  style={{...TextStyle, fontSize: 14, fontWeight: 500}}>{type}</Typography.Title>
            )}
            <Space className={'basic-type'} style={{width: '100%'}}>
                {labels.map((label, index) => <FacilityLabel key={index} label={label}/>)}
            </Space>
        </>
    );
}
