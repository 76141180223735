import dayjs, {Dayjs} from "dayjs";

export const yyyymmdd = (format: string) => {
    try {
        return dayjs(format).year(dayjs().year()).format('YYYY/MM/DD');
    } catch (e) {
        return ''
    }
}

export const convertDateFormat = (originDateString: string, format: string) => {
    const parsedDate = dayjs(originDateString, 'YY.MM.DD (ddd)')
    return parsedDate.format(format)
}
