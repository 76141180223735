import ScreenOuter from "../../components/ScreenOuter";
import React, {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {calculateStayDuration} from "../AccommodationScreen/util";
import {Button, Col, Space, Typography} from "antd";
import useWindowDimensions, {Color, TextStyle} from "../../constants/style";
import {ImageCarousel, mockImages} from "../AccommodationScreen/components/ImageCarousel";
import NoBorderButton from "../../components/NoBorderButton";
import {InfoCircleFilled, InfoCircleOutlined, MonitorOutlined} from "@ant-design/icons";
import {searchNaverMap} from "../../utils/map";
import {LongTextExpand} from "../../components/LongTextExpand";
import EmptySpace from "../../components/EmptySpace";
import {FacilityTypeComponent} from "./components/AccommodationBasicFacility";
import NavigationService from "../../services/NavigationService";
import {moneyValue} from "../../utils/string";
import {PageRoutes} from "../../App";
import {calculatePrice} from "../../utils/price";
import {useDispatch, useSelector} from "react-redux";
import {requestRoom} from "../../redux/Room";
import {requestAccommodation} from "../../redux/Accommodation";
import _ from "lodash";

const AccommodationRoomScreen = ({}) => {
    const {accommodationId, roomId} = useParams()

    const dispatch = useDispatch()

    const location = useLocation()
    const dateAndCustomerInfo = location?.state?.dateAndCustomerInfo || {}
    const navigate = useNavigate()

    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth : 412

    useEffect(() => {
        NavigationService.scrollToTop()

        // @ts-ignore
        dispatch(requestAccommodation(accommodationId))
        // @ts-ignore
        dispatch(requestRoom(roomId))
    }, [])

    // @ts-ignore
    const accommodation = useSelector(state => state.Accommodation.toJS())
    // @ts-ignore
    const room = useSelector(state => state.Room.toJS())
    const basicFacilityMap = _.groupBy(room?.basicFacilities, (b) => b.type)

    const numberOfRoomString = `거실 ${room.numberOfLivingRoom}, 방 ${room.numberOfRoom}, 화장실 ${room.numberOfBathroom}`
    const reservationParam = {
        dateAndCustomerInfo: dateAndCustomerInfo,
        accommodationId: accommodation.id,
        accommodationName: accommodation.name,
        roomId: room.id,
    }

    return (
        <ScreenOuter headerFixed headerType={'back'} header={makeHeader(dateAndCustomerInfo)} footerBottom={96}>
            <ImageCarousel topRound bottomRound paddingHorizontal={20} height={210} title={room.name}
                           images={room.contents}/>
            <Space direction={'vertical'}
                   style={{flex: 1, paddingLeft: 20, paddingRight: 20, marginTop: 80, marginBottom: 40}}>
                <Typography.Text
                    style={{...TextStyle, fontSize: 20, fontWeight: 700}}>{room.name}</Typography.Text>
                <Space style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400
                    }}>{accommodation.address}<br/>{accommodation.addressDetail}</Typography.Text>
                    <NoBorderButton
                        style={{...TextStyle, fontSize: 14, fontWeight: 500, color: Color.b40}}
                        icon={<MonitorOutlined/>}
                        onClick={() => searchNaverMap(`${accommodation?.address} ${accommodation?.addressDetail}`)}
                        value={'지도 보기'}
                    />
                </Space>
                {/*<LongTextExpand maxLength={80} text={accommodation?.description}/>*/}
            </Space>
            <EmptySpace size={10} color={Color.black3}/>

            <Space direction={'vertical'}
                   style={{rowGap: 0, width: '100%', paddingLeft: 20, paddingRight: 20, marginTop: 24}}>

                <Space direction={'vertical'} style={{marginTop: 0}}>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        객실 구성
                    </Typography.Text>
                    <li style={{paddingLeft: 10}}>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 400,
                        }}>{room?.roomSize}평</Typography.Text>
                    </li>
                    <li style={{paddingLeft: 10}}>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 16,
                        }}>{numberOfRoomString}</Typography.Text>
                    </li>
                </Space>

                <Space direction={'vertical'} style={{marginTop: 40}}>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        입실/퇴실
                    </Typography.Text>
                    <li style={{paddingLeft: 10}}>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 16,
                        }}>{`입실: ${accommodation?.checkInTime}, 퇴실: ${accommodation?.checkOutTime}`}</Typography.Text>
                    </li>
                </Space>

                <Space direction={'vertical'} style={{width: '100%', marginTop: 40}}>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        기준 인원
                    </Typography.Text>
                    <li style={{paddingLeft: 10}}>
                        <Typography.Text style={{
                            ...TextStyle,
                            fontSize: 14,
                            fontWeight: 400,
                            marginTop: 16,
                        }}>기준: {room?.standardNumberOfPeople}명, 최대: {room?.maxNumberOfPeople}명</Typography.Text>
                    </li>
                    <Space direction={'vertical'}
                           style={{
                               rowGap: 0,
                               marginTop: 16,
                               width: '100%',
                               paddingLeft: 16,
                               paddingRight: 16,
                               paddingTop: 12,
                               paddingBottom: 12,
                               borderRadius: 16,
                               backgroundColor: Color.black3
                           }}>
                        <Space>
                            <InfoCircleFilled style={{marginBottom: 3, color: Color.darkTertiary}}/>
                            <Typography.Text
                                style={{...TextStyle, fontSize: 12, fontWeight: 700, color: Color.darkTertiary}}>
                                기준 인원 초과 시, 추가 요금이 발생합니다.</Typography.Text>
                        </Space>
                        <li style={{paddingLeft: 10, color: Color.darkTertiary, marginTop: 10}}>
                            <Typography.Text
                                style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                                성인 (만 13세 이상) : {moneyValue(room?.additionalFeeAdult)}원
                            </Typography.Text>
                        </li>
                        <li style={{paddingLeft: 10, color: Color.darkTertiary}}>
                            <Typography.Text
                                style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                                아동 (만 2세 ~ 12세) : {moneyValue(room?.additionalFeeChild)}원
                            </Typography.Text>
                        </li>
                        <li style={{paddingLeft: 10, color: Color.darkTertiary}}>
                            <Typography.Text
                                style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                                유아 (24개월 미만) : {moneyValue(room?.additionalFeeBaby)}원
                            </Typography.Text>
                        </li>
                    </Space>
                </Space>

                <Space direction={'vertical'} style={{marginTop: 40}}>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        유료 시설
                    </Typography.Text>
                    <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                        ※ 예약 진행 시, 추가 할 수 있어요.
                    </Typography.Text>
                    <FacilityTypeComponent
                        labels={room?.paidFacilities.map((paidFacility: any) => paidFacility.name)}
                    />
                </Space>

                <Space direction={'vertical'} style={{marginTop: 40}}>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        기본 시설
                    </Typography.Text>

                    <FacilityTypeComponent
                        type={'주변'}
                        labels={basicFacilityMap['주변']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'침실'}
                        labels={basicFacilityMap['침실']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'주방'}
                        labels={basicFacilityMap['주방']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'욕실'}
                        labels={basicFacilityMap['욕실']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'냉/난방'}
                        labels={basicFacilityMap['냉/난방']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'엔터테인먼트'}
                        labels={basicFacilityMap['엔터테인먼트']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'주차'}
                        labels={basicFacilityMap['주차']?.map(b => b.name) || []}
                    />
                    <FacilityTypeComponent
                        type={'기타'}
                        labels={basicFacilityMap['기타']?.map(b => b.name) || []}
                    />
                </Space>
            </Space>
            <Space style={{
                position: 'fixed',
                bottom: 0,
                width: width,
                height: 96,
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(252, 252, 253, 0.90)',
            }}>
                <Button
                    style={{
                        width: width,
                        height: 64,
                        backgroundColor: Color.y40,
                        ...TextStyle,
                        fontSize: 16,
                        fontWeight: 700,
                    }}
                    onClick={() => navigate(PageRoutes.ReservationScreen, {state: reservationParam})}
                >예약하기</Button>
            </Space>
        </ScreenOuter>
    )
}

function makeHeader(dateAndCustomerInfo: any) {
    return dateAndCustomerInfo?.startDateString && dateAndCustomerInfo?.endDateString ? (
        <Space direction={'vertical'}
               style={{flex: 1, lineHeight: 0, marginBottom: 20, alignItems: 'center', justifyContent: 'center'}}>
            <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 400}}>
                {dateAndCustomerInfo?.startDateString} ~ {dateAndCustomerInfo?.endDateString} / {calculateStayDuration(dateAndCustomerInfo?.startDateString, dateAndCustomerInfo?.endDateString)}
            </Typography.Text>
            <Typography.Text style={{...TextStyle, fontSize: 12, fontWeight: 400}}>
                성인 {dateAndCustomerInfo?.adult}, 아동 {dateAndCustomerInfo?.child}, 유아 {dateAndCustomerInfo?.baby}
            </Typography.Text>
        </Space>
    ) : null
}

export default AccommodationRoomScreen
