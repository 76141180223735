import {useState, useEffect, CSSProperties} from 'react';

export const Color = {
    white: '#ffffff',
    darkBlue: '#2c2f4e',
    greyBorder: '#e8e8ed',
    lightGrey: '#f3f4f7',
    textGrey: '#bdbec9',

    darkPrimary: '#1C1C20',
    darkSecondary: '#60606C',
    darkTertiary: '#93939F',
    darkDisabled: '#C9C9CF',

    black3: '#F7F7F8',
    black5: '#F1F1F3',
    black10: '#E5E5E8',
    black11: '#E5E5E7',

    r50: '#E92216',
    r40: '#FF6157',
    r30: '#FF6F66',
    r20: '#FF9F99',
    r10: '#FFCFCC',
    r5: '#FFE7E5',
    r3: '#FFF1F0',

    b50: '#122BED',
    b40: '#5266FF',
    b30: '#6678FF',
    b20: '#99A5FF',
    b10: '#CCD2FF',
    b5: '#E5E8FF',
    b3: '#F0F1FF',
    b1: '#FCFCFD',

    y50: '#FCD703',
    y40: '#FDDF35',
    y30: '#FFE866',
    y20: '#FFF099',
    y10: '#FFF7CC',
    y5: '#FFFBE5',
    y3: '#FFFDF0',

    liner: 'linear-gradient(to top, #FCD703 20%, transparent 40%)',
}

export const TextStyle: CSSProperties = {
    whiteSpace: 'pre-line', // 개행문자 적용
    color: Color.darkPrimary,
    flexShrink: 0,
    fontFamily: 'Spoqa Han Sans Neo',
}

export const DividerStyle = {
    marginTop: 4,
    marginLeft: 4,
    marginRight: 4,
    width: 2,
    height: 24,
    backgroundColor: Color.b5
}

export const MobileSizeStyle = {
    maxWidth: '412px',
    minWidth: '360px',
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        deviceWidth: width,
        deviceHeight: height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        ...windowDimensions,
        isSmallWidthDevice: windowDimensions.deviceWidth < 412,
        isSmallHeightDevice: windowDimensions.deviceHeight < 741,
        isSmallResolutionDevice: windowDimensions.deviceWidth / windowDimensions.deviceHeight < 0.6
    };
}
