import React, {useEffect, useState} from "react";
import {Input, Space} from "antd";
import NoBorderButton from "../NoBorderButton";
import {Color, TextStyle} from "../../constants/style";

export const NumberIncrement = ({disabled = false, initialValue = 0, onChange, minValue = 0}: {
    disabled?: boolean,
    initialValue: number,
    onChange: (value: number) => void,
    minValue?: number,
}) => {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        onChange(value)
    }, [value])

    const minus = () => {
        const v = value >= (minValue + 1) ? value - 1 : minValue
        setValue(v)
    }
    const plus = () => {
        if (disabled) {
            return
        }

        setValue(value => ++value)
    }

    return (
        <Space>
            <NoBorderButton
                style={{width: 50, height: 50, backgroundColor: Color.black5, borderRadius: 16, fontSize: 24}}
                onClick={minus} value={'-'}/>
            <Input
                disabled={disabled}
                style={{
                    width: 80,
                    height: 50,
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    ...TextStyle,
                    fontSize: 16
                }}
                value={value}
                onChange={() => null}
            />
            <NoBorderButton
                style={{width: 50, height: 50, backgroundColor: Color.black5, borderRadius: 16, fontSize: 24}}
                onClick={plus} value={'+'}/>
        </Space>
    )
}
