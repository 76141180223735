import {Map} from 'immutable';
import {SET_IS_LOADING_RESERVATION, SET_IS_LOADING_ROOM, setIsLoading} from "../Loading";
import RoomApi from "../../apis/RoomApi";
import ReservationApi from "../../apis/ReservationApi";

// action types

// action creators

// initialState
const initialState = Map({});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        default:
            return state;
    }
};

export interface Reservation {
    accommodationId: number,
    roomId: number,
    name: string,
    phoneNumber: string,
    birthday: string,
    type: 'BANK_ACCOUNT' | 'CREDIT_CARD',
    totalPrice: number,
    roomPrice: number,
    additionalCustomerPrice: number,
    options: { optionName: string, optionPrice: number }[],
    adultCount: number,
    childCount: number,
    babyCount: number,
    comment: string,
    startDate: string,
    endDate: string,
    agreedUsageTerm: boolean,
    agreedPrivacyTerm: boolean,
    agreedPrivacyThirdPartyTerm: boolean,
}

export const createReservation = (reservation: Reservation) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.create(reservation);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: true, data: {reservationCode: json?.data?.reservationCode}})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
    }
};

export const searchReservation = (name: string, phoneNumber: string, reservationCode: string) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.get(name, phoneNumber, reservationCode);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: true, data: {reservation: json?.data}})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
    }
}
