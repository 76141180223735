import ScreenOuter from "../../components/ScreenOuter";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Modal, Space, Typography} from "antd";
import useWindowDimensions, {Color, TextStyle} from "../../constants/style";
import RequiredFieldMark from "../../components/RequiredFieldMark";
import {formatPhoneNumber, removeNumbersAndSpecialCharacters} from "../../utils/string";
import {useDispatch} from "react-redux";
import {searchReservation} from "../../redux/Reservation";
import {PageRoutes} from "../../App";

const ReservationSearchScreen = ({}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal()

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [reservationCode, setReservationCode] = useState('')

    const {deviceWidth, isSmallWidthDevice} = useWindowDimensions()
    const width = isSmallWidthDevice ? deviceWidth - 40 : 372

    const [form] = Form.useForm();
    const nameInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const reservationCodeInputRef = useRef(null);

    const handleInputErrors = (errorFields: any[]) => {
        if (errorFields.length > 0) {
            const firstErrorField = errorFields[0].name[0];
            if (firstErrorField === 'name') {
                // @ts-ignore
                nameInputRef?.current?.focus();
            } else if (firstErrorField === 'phoneNumber') {
                // @ts-ignore
                phoneNumberInputRef?.current?.focus();
            } else if (firstErrorField === 'reservationCode') {
                // @ts-ignore
                reservationCodeInputRef?.current?.focus();
            }
        }
    }

    const ErrorMessage = ({message}: { message: string }) => {
        return (
            <Typography.Text style={{
                ...TextStyle,
                marginLeft: 22,
                fontSize: 14,
                fontWeight: '700',
                color: Color.r40
            }}>{message}</Typography.Text>
        )
    }

    const requestSearchReservation = async () => {
        // @ts-ignore
        const {success, data, error} = await dispatch(searchReservation(name, phoneNumber, reservationCode))

        if (success) {
            navigate(PageRoutes.ReservationCheckScreen, {state: data.reservation})
        } else {
            modal.error({
                title: error.title,
                content: error.message
            })
        }
    }

    const completedAllFields = !!(name && phoneNumber && reservationCode)

    return (
        <ScreenOuter>
            <Space direction={'vertical'} style={{marginTop: 30, paddingLeft: 20, paddingRight: 20}}>
                <Typography.Text style={{...TextStyle, fontSize: 26, fontWeight: 700}}>
                    예약 확인
                </Typography.Text>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400}}>
                    예약 시 입력했던 예약자명, 휴대폰번호를 입력하세요.
                </Typography.Text>
            </Space>

            <Form
                form={form}
                style={{width: width}}
                onFinishFailed={(errorInfo) => handleInputErrors(errorInfo.errorFields)}
                onFinish={requestSearchReservation}
            >

                <Form.Item name={"name"} style={{marginTop: 30, width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'예약자명을 입력해주세요.'}/>}]}>
                    <Space direction={'vertical'} style={{paddingLeft: 20, paddingRight: 20}}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500}}>
                            예약자명 <RequiredFieldMark/>
                        </Typography.Text>
                        <Input
                            style={{width: width, height: 50, borderRadius: 16, ...TextStyle, fontSize: 14}}
                            placeholder={'홍길동'}
                            value={name}
                            onChange={(e) => setName(removeNumbersAndSpecialCharacters(e?.target?.value))}
                        />
                    </Space>
                </Form.Item>

                <Form.Item name={"phoneNumber"} style={{marginTop: 30, width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'휴대폰번호를 입력해주세요.'}/>}]}>
                    <Space direction={'vertical'} style={{paddingLeft: 20, paddingRight: 20}}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500}}>
                            휴대폰번호 <RequiredFieldMark/>
                        </Typography.Text>
                        <Input
                            style={{width: width, height: 50, borderRadius: 16, ...TextStyle, fontSize: 14}}
                            minLength={11}
                            maxLength={13}
                            placeholder={'숫자만 입력'}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(formatPhoneNumber(e?.target?.value))}
                        />
                    </Space>
                </Form.Item>

                <Form.Item name={"reservationCode"} style={{marginTop: 30, width: width}}
                           rules={[{required: true, message: <ErrorMessage message={'예약 번호를 입력해주세요.'}/>}]}>
                    <Space direction={'vertical'} style={{paddingLeft: 20, paddingRight: 20}}>
                        <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500}}>
                            예약 번호 <RequiredFieldMark/>
                        </Typography.Text>
                        <Input
                            style={{width: width, height: 50, borderRadius: 16, ...TextStyle, fontSize: 14}}
                            minLength={8}
                            maxLength={8}
                            placeholder={'AA로 시작하는 8자리'}
                            value={reservationCode}
                            onChange={(e) => setReservationCode((e?.target?.value))}
                        />
                        <Typography.Text
                            style={{...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkTertiary}}>
                            ※ 예약번호는 예약 시 받았던 문자에서 확인할 수 있습니다.
                        </Typography.Text>
                    </Space>
                </Form.Item>

                <Form.Item name={"button"}>
                    <Space style={{marginTop: 30, paddingLeft: 20, paddingRight: 20}}>
                        <Button
                            htmlType={"submit"}
                            style={{
                                width: width,
                                height: 64,
                                borderRadius: 16,
                                borderColor: completedAllFields ? Color.y40 : Color.black5,
                                backgroundColor: completedAllFields ? Color.y40 : Color.black5,
                                ...TextStyle,
                                fontSize: 16,
                                fontWeight: 700,
                                color: completedAllFields ? Color.darkPrimary : Color.darkDisabled,
                            }}>예약 확인하기</Button>
                    </Space>
                </Form.Item>
            </Form>
            {contextHolder}
        </ScreenOuter>
    )
}

export default ReservationSearchScreen
