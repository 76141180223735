import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppBase from "./screens/AppBase";
import AccommodationScreen from "./screens/AccommodationScreen";
import ReservationSearchScreen from "./screens/ReservationSearchScreen";
import NotificationScreen from "./screens/NotificationScreen";
import ImagesScreen from "./screens/ImagesScreen";
import AccommodationRoomScreen from "./screens/AccommodationRoomScreen";
import {ReservationScreen} from "./screens/ReservationScreen";
import ReservationCheckScreen from "./screens/ReservationCheckScreen";

export const PageRoutes = {
    // Accommodations
    Accommodations: '/accommodations/:id',
    AccommodationRooms: '/accommodations/:accommodationId/rooms/:roomId',

    // Reservations
    ReservationScreen: '/reservations',
    ReservationSearchScreen: '/reservations/search',
    ReservationCheckScreen: '/reservations/check',

    // Notifications
    Notifications: '/notifications',

    // Contents
    Images: '/images',

    // utils
    dynamicPath: (...params: any[]) => {
        if (params.length % 2 !== 0) {
            throw new Error('잘못된 매개변수입니다.');
        }

        let path = '';
        for (let i = 0; i < params.length; i += 2) {
            if (i === params.length - 2) {
                path += `${params[i]}/${params[i + 1]}`;
            } else {
                path += `${params[i]}/${params[i + 1]}`;
            }
        }

        return path;
    },
}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<AppBase/>}/>

                {/* Accommodations */}
                <Route path={PageRoutes.Accommodations} element={<AccommodationScreen/>}/>
                <Route path={PageRoutes.AccommodationRooms} element={<AccommodationRoomScreen/>}/>

                {/* Reservations */}
                <Route path={PageRoutes.ReservationScreen} element={<ReservationScreen/>}/>
                <Route path={PageRoutes.ReservationCheckScreen} element={<ReservationCheckScreen/>}/>
                <Route path={PageRoutes.ReservationSearchScreen} element={<ReservationSearchScreen/>}/>

                {/* Notifications */}
                <Route path={PageRoutes.Notifications} element={<NotificationScreen/>}/>

                {/* Images */}
                <Route path={PageRoutes.Images} element={<ImagesScreen/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
