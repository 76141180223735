import React, {useState} from "react";
import {Modal, Space, Typography} from "antd";
import {Color, MobileSizeStyle, TextStyle} from "../../../constants/style";
import NoBorderButton from "../../../components/NoBorderButton";
import EmptySpace from "../../../components/EmptySpace";
import {NumberIncrement} from "../../../components/NumberIncrement";


export const CustomerInfoModal = ({isOpenModal, onOk, onCancel, initialAdult, initialChild, initialBaby}: {
    isOpenModal: boolean,
    onOk: (adult: number, child: number, baby: number) => void,
    onCancel: () => void,
    initialAdult: number,
    initialChild: number,
    initialBaby: number,
}) => {
    const [adult, setAdult] = useState(initialAdult || 2)
    const [child, setChild] = useState(initialChild || 0)
    const [baby, setBaby] = useState(initialBaby || 0)

    const [modal, contextHolder] = Modal.useModal()

    if (!isOpenModal) {
        return null
    }

    const onClickOk = () => {
        onOk(adult, child, baby)
    }

    return (
        <Modal
            open={true}
            footer={<ModalFooter onOk={onClickOk} onCancel={onCancel}/>}
            centered={true}
            wrapClassName={'bottom-center-modal'}
            closable={false}
            width={'100%'}
            style={{
                ...MobileSizeStyle,
                position: 'fixed',
                top: 'auto',
                bottom: -20,
                transform: 'translate(0, 0)',
            }}
        >
            <Space direction={'vertical'} style={{width: '100%'}}>
                <Typography.Text style={{...TextStyle, fontSize: 20, fontWeight: 700}}>예약 인원</Typography.Text>
                <EmptySpace size={10}/>

                <Space style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Label label={'성인'} secondaryLabel={'만 13세 이상'}/>
                    <NumberIncrement
                        initialValue={adult}
                        minValue={1}
                        onChange={value => setAdult(value)}
                    />
                </Space>
                <EmptySpace size={3}/>

                <Space style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Label label={'아동'} secondaryLabel={'만 2 ~ 12세'}/>
                    <NumberIncrement
                        initialValue={child}
                        onChange={value => setChild(value)}
                    />
                </Space>
                <EmptySpace size={3}/>

                <Space style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Label label={'유아'} secondaryLabel={'24개월 미만'}/>
                    <NumberIncrement
                        initialValue={baby}
                        onChange={value => setBaby(value)}
                    />
                </Space>
            </Space>
            {contextHolder}
        </Modal>
    )
}

const Label = ({label, secondaryLabel}: { label: string, secondaryLabel: string }) => {
    return (
        <Space direction={'vertical'} style={{rowGap: 0}}>
            <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 500}}>{label}</Typography.Text>
            <Typography.Text style={{
                ...TextStyle,
                fontSize: 14,
                fontWeight: 400,
                color: Color.darkSecondary,
                padding: 0
            }}>{secondaryLabel}</Typography.Text>
        </Space>
    )
}

const ModalFooter = ({onOk, onCancel}: any) => (
    <Space
        className={'border-top'}
        style={{
            width: '100%',
            height: 82,
            alignItems: 'center',
            justifyContent: 'space-evenly'
        }}>
        <NoBorderButton
            style={{
                width: 140,
                height: 50,
                borderRadius: 16,
                backgroundColor: Color.black5, ...TextStyle,
                fontSize: 16,
                fontWeight: 700,
            }}
            value={'닫기'}
            onClick={onCancel}
        />
        <NoBorderButton
            style={{
                width: 140,
                height: 50,
                backgroundColor: Color.y40, ...TextStyle,
                fontSize: 16,
                fontWeight: 700
            }}
            value={'확인'}
            onClick={onOk}
        />
    </Space>
)
