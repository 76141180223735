// @ts-nocheck

import ScreenOuter from "../../components/ScreenOuter";
import React, {CSSProperties, useEffect, useState} from "react";
import {
    Button,
    Calendar,
    Carousel,
    DatePicker,
    Divider,
    Image, message,
    Modal,
    notification,
    Select,
    Space,
    Typography
} from "antd";
import useWindowDimensions, {Color, MobileSizeStyle, TextStyle} from "../../constants/style";
import {MonitorOutlined, PictureFilled, ShareAltOutlined} from "@ant-design/icons";
import {FALLBACK_IMAGE} from "../../constants/content";

import NoBorderButton from "../../components/NoBorderButton";

import {searchNaverMap} from "../../utils/map";
import {copyToClipboard} from "../../utils/string";
import {DEFAULT_ACCOMMODATION_ID, FRONT_URL} from "../../constants/environment";
import {Navigate, useLocation, useParams, useSearchParams} from "react-router-dom";
import {PageRoutes} from "../../App"
import {dateAndCustomerInfoContainerStyle, dateAndCustomerInfoStyle} from "./style"
import CalendarIcon from '../../assets/icon/calendar.png'
import HumanIcon from '../../assets/icon/human.png'

import 'antd/dist/reset.css'
import './index.css'

import ko from 'antd/es/date-picker/locale/ko_KR';
import 'dayjs/locale/ko';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import TextLiner from "../../components/TextLiner";
import {DateModal} from "./components/DateModal";
import {calculateStayDuration, determineWeekend} from "./util";
import {CustomerInfoModal} from "./components/CustomerInfoModal";
import {ReservationTab} from "./components/ReservationTab";
import {ReservationRooms} from "./components/ReservationRooms";
import {ImageCarousel, mockImages} from "./components/ImageCarousel";
import {AccommodationInfo} from "./components/AccommodationInfo";
import {SessionStorageService} from "../../services/SessionStorageService";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodation, setIsAccommodationNotFound} from "../../redux/Accommodation";
import NavigationService from "../../services/NavigationService";
import BlockScreenLoadingComponent from "../../components/LoadingModal";

dayjs.locale('ko')
dayjs.extend(isBetween)

const AccommodationScreen = ({}) => {
    const dispatch = useDispatch()
    const dateAndCustomerInfo = SessionStorageService.getDateAndCustomerInfo()

    const weekend = determineWeekend()
    const [startDateString, setStartDateString] = useState(dateAndCustomerInfo?.startDateString || weekend.startDateString)
    const [endDateString, setEndDateString] = useState(dateAndCustomerInfo?.endDateString || weekend.endDateString)
    const [adult, setAdult] = useState(dateAndCustomerInfo?.adult || 2)
    const [child, setChild] = useState(dateAndCustomerInfo?.child || 0)
    const [baby, setBaby] = useState(dateAndCustomerInfo?.baby || 0)

    const {id} = useParams()
    const accommodationId = id

    // @ts-ignore
    const isAccommodationNotFound = useSelector(state => state.Accommodation.get('isAccommodationNotFound'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationAddress = useSelector(state => state.Accommodation.get('address'))
    // @ts-ignore
    const accommodationAddressDetail = useSelector(state => state.Accommodation.get('addressDetail'))
    // @ts-ignore
    const accommodationDescription = useSelector(state => state.Accommodation.get('description'))
    // @ts-ignore
    const accommodationOfficePhoneNumber = useSelector(state => state.Accommodation.get('officePhoneNumber'))
    // @ts-ignore
    const accommodationCheckInTime = useSelector(state => state.Accommodation.get('checkInTime'))
    // @ts-ignore
    const accommodationCheckOutTime = useSelector(state => state.Accommodation.get('checkOutTime'))
    // @ts-ignore
    const accommodationUsagePolicy = useSelector(state => state.Accommodation.get('usagePolicy'))
    // @ts-ignore
    const accommodationRefundPolicy = useSelector(state => state.Accommodation.get('refundPolicy'))
    // @ts-ignore
    const accommodationRefundPercentages = useSelector(state => state.Accommodation.get('refundPercentages'))
    // @ts-ignore
    const accommodationContents = useSelector(state => state.Accommodation.get('contents'))

    useEffect(() => {
        if (accommodationId !== ':id') {
            // @ts-ignore
            dispatch(requestAccommodation(accommodationId))
        }
    }, [])

    useEffect(() => {
        SessionStorageService.saveDateAndCustomerInfo(startDateString, endDateString, adult, child, baby)
    }, [startDateString, endDateString, adult, child, baby])

    if (accommodationId === ':id' || !accommodationId || isAccommodationNotFound) {
        dispatch(setIsAccommodationNotFound(false))
        NavigationService.moveTo(`/accommodations/${DEFAULT_ACCOMMODATION_ID}`)
        return <></>
    }

    return (
        <ScreenOuter>
            <ImageCarousel title={accommodationName} images={accommodationContents}/>
            <Space direction={'vertical'}
                   style={{flex: 1, paddingLeft: 20, paddingRight: 20, marginTop: 80}}>
                <Typography.Text
                    style={{...TextStyle, fontSize: 20, fontWeight: 700}}>{accommodationName}</Typography.Text>
                <Space style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400
                    }}>{accommodationAddress}<br/>{accommodationAddressDetail}</Typography.Text>
                    <NoBorderButton
                        style={{...TextStyle, fontSize: 14, fontWeight: 500, color: Color.b40}}
                        icon={<MonitorOutlined/>}
                        onClick={() => searchNaverMap(accommodationAddress + " " + accommodationAddressDetail)}
                        value={'지도 보기'}
                    />
                </Space>
            </Space>
            <ShareButton accommodationId={accommodationId}/>
            <DateAndCustomerInfo
                startDateString={startDateString}
                endDateString={endDateString}
                adult={adult}
                child={child}
                baby={baby}
                onChangeStartDateString={(startDateString: string) => setStartDateString(startDateString)}
                onChangeEndDateString={(endDateString: string) => setEndDateString(endDateString)}
                onChangeAdult={(adult: number) => setAdult(adult)}
                onChangeChild={(child: number) => setChild(child)}
                onChangeBaby={(baby: number) => setBaby(baby)}
            />
            <ReservationTab
                // @ts-ignore
                Rooms={
                    <ReservationRooms
                        accommodationId={parseInt(accommodationId)}
                        startDateString={startDateString}
                        endDateString={endDateString}
                        adult={adult}
                        child={child}
                        baby={baby}/>}
                AccommodationInfo={
                    <AccommodationInfo
                        accommodation={{
                            description: accommodationDescription,
                            officePhoneNumber: accommodationOfficePhoneNumber,
                            checkInTime: accommodationCheckInTime,
                            checkOutTime: accommodationCheckOutTime,
                            usagePolicy: accommodationUsagePolicy,
                            refundPolicy: accommodationRefundPolicy,
                            refundPercentages: accommodationRefundPercentages
                        }}
                    />
                }
            />
        </ScreenOuter>
    )
}

const ShareButton = ({accommodationId}: { accommodationId: string }) => {
    return (
        <Space style={{marginTop: 24, paddingLeft: 20, paddingRight: 20}}>
            <Button style={{
                width: 'auto',
                height: 36,
                borderRadius: 8,
                borderColor: Color.b5,
                backgroundColor: Color.b5,
                alignItems: 'center',
                justifyContent: 'center',
                ...TextStyle,
                fontSize: 14,
                fontWeight: 500
            }} icon={<ShareAltOutlined/>} onClick={() => {
                copyToClipboard(`${FRONT_URL}${PageRoutes.dynamicPath('/accommodations', accommodationId)}`)
                notification.success({message: '클립보드에 복사되었습니다! 친구에게 공유해보세요.', closeIcon: false})
            }}>공유 </Button>
        </Space>
    )
}

const DateAndCustomerInfo = ({
                                 startDateString,
                                 endDateString,
                                 adult,
                                 child,
                                 baby,
                                 onChangeStartDateString,
                                 onChangeEndDateString,
                                 onChangeAdult,
                                 onChangeChild,
                                 onChangeBaby,
                             }: {
    startDateString: string,
    endDateString: string,
    adult: number,
    child: number,
    baby: number,
    onChangeStartDateString: (v: string) => void,
    onChangeEndDateString: (v: string) => void,
    onChangeAdult: (v: number) => void,
    onChangeChild: (v: number) => void,
    onChangeBaby: (v: number) => void,
}) => {
    const [isOpenDateModal, setIsOpenDateModal] = useState(false)
    const [isOpenCustomerInfoModal, setIsOpenCustomerInfoModal] = useState(false)

    return (
        <Space style={{display: 'block', paddingLeft: 20, paddingRight: 20, marginTop: 20}}>
            <Space direction={'vertical'} style={{...dateAndCustomerInfoContainerStyle}}>
                <Space
                    className={'clickable'}
                    style={{...dateAndCustomerInfoStyle, border: 'solid #F1F1F3', borderWidth: '0 0 1px 0'}}
                    onClick={() => setIsOpenDateModal(true)}
                >
                    <Image preview={false} src={CalendarIcon}/>
                    <Typography.Text
                        style={{...TextStyle, fontSize: 14, fontWeight: 400}}>{startDateString}</Typography.Text>
                    <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400}}>~</Typography.Text>
                    <Typography.Text
                        style={{...TextStyle, fontSize: 14, fontWeight: 400}}>{endDateString}</Typography.Text>
                    <div style={{width: 4, height: 4, borderRadius: 8, backgroundColor: '#AEAE88'}}></div>
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 400
                    }}>{calculateStayDuration(startDateString, endDateString)}</Typography.Text>
                </Space>
                <Space
                    className={'clickable'}
                    style={{...dateAndCustomerInfoStyle, paddingBottom: 8}}
                    onClick={() => setIsOpenCustomerInfoModal(true)}
                >
                    <Image preview={false} src={HumanIcon}/>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 400}}>성인 {adult}</Typography.Text>
                    <div style={{width: 4, height: 4, borderRadius: 8, backgroundColor: '#AEAE88'}}></div>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 400}}>아동 {child}</Typography.Text>
                    <div style={{width: 4, height: 4, borderRadius: 8, backgroundColor: '#AEAE88'}}></div>
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 400}}>유아 {baby}</Typography.Text>
                </Space>
            </Space>
            <DateModal
                isOpenModal={isOpenDateModal}
                onOk={(startDateString: string, endDateString: string) => {
                    setIsOpenDateModal(false)
                    onChangeStartDateString(startDateString)
                    onChangeEndDateString(endDateString)
                }}
                onCancel={() => setIsOpenDateModal(false)}
            />
            <CustomerInfoModal
                isOpenModal={isOpenCustomerInfoModal}
                onOk={(adult, child, baby) => {
                    setIsOpenCustomerInfoModal(false)
                    onChangeAdult(adult)
                    onChangeChild(child)
                    onChangeBaby(baby)
                }}
                onCancel={() => setIsOpenCustomerInfoModal(false)}
                initialAdult={adult}
                initialChild={child}
                initialBaby={baby}
            />
        </Space>
    )
}

export default AccommodationScreen
