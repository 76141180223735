const SELECTED_DATE_STRING_KEY = 'SELECTED_DATE_STRING'

export class SessionStorageService {

    static saveDateAndCustomerInfo(startDateString: string, endDateString: string, adult: number, child: number, baby: number) {
        sessionStorage.setItem(SELECTED_DATE_STRING_KEY, JSON.stringify({
            startDateString,
            endDateString,
            adult,
            child,
            baby
        }))
    }

    static getDateAndCustomerInfo() {
        const dateAndCustomerInfo = sessionStorage.getItem(SELECTED_DATE_STRING_KEY)
        return dateAndCustomerInfo ? JSON.parse(dateAndCustomerInfo) : null
    }
}
