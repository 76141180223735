export const PROFILE = "dev"

export const env = (map: any) => map[PROFILE]

const DEV_DOMAIN = 'dev-api.staytwice.com'

const _API_URL = {
    local: `https://${DEV_DOMAIN}`,
    dev: `https://${DEV_DOMAIN}`,
    prod: 'http://localhost:8080',
};

export const API_URL = (function () {
    return env(_API_URL)
})()

const _FRONT_URL = {
    local: 'http://localhost:4000',
    dev: 'https://dev.staytwice.com',
    prod: '',
}

export const FRONT_URL = (function () {
    return env(_FRONT_URL)
})()

export const LOGIN_URL = (function () {
    return `https://${DEV_DOMAIN}/login`
})()

export const DEFAULT_ACCOMMODATION_ID = '1'
