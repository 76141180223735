import {Flex, Space, Spin} from "antd";
import {useSelector} from "react-redux";

const BlockScreenLoadingComponent = ({}) => {
    // @ts-ignore
    const isLoadingAccommodation = useSelector(state => state.Loading.get('isLoadingAccommodation'))
    // @ts-ignore
    const isLoadingRoom = useSelector(state => state.Loading.get('isLoadingRoom'))
    // @ts-ignore
    const isLoadingReservation = useSelector(state => state.Loading.get('isLoadingReservation'))

    if (!isLoadingAccommodation && !isLoadingRoom && !isLoadingReservation) {
        return <></>
    }

    return (
        <Space style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)', // 투명한 백그라운드
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        }}>
            <Flex align={"center"} gap={"middle"}>
                <Spin size={"large"}/>
            </Flex>
        </Space>
    )
}

export default BlockScreenLoadingComponent
