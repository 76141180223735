import {combineReducers} from 'redux'

import * as Loading from './Loading'
import * as Accommodation from './Accommodation'
import * as Room from './Room'

const reducers = combineReducers({
    Loading: Loading.reducer,
    Accommodation: Accommodation.reducer,
    Room: Room.reducer,
});

export {
    reducers,
    Loading,
    Accommodation,
    Room,
};