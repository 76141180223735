import ScreenOuter from "../../components/ScreenOuter";
import {useLocation} from "react-router-dom";
import {Image} from "antd";
import {FALLBACK_IMAGE} from "../../constants/content";
import React, {useEffect} from "react";
import NavigationService from "../../services/NavigationService";

const ImagesScreen = ({}) => {
    const location = useLocation()
    const headerTitle = location?.state?.headerTitle || ''
    const images = location?.state?.images || []

    useEffect(() => {
        NavigationService.scrollToTop()
    }, [])

    return (
        <ScreenOuter headerType={'back'} headerTitle={headerTitle}>
            {images.map((image: any, index: number) => (
                <Image
                    key={index}
                    className={'image'}
                    fallback={FALLBACK_IMAGE}
                    style={{padding: 3}}
                    src={image.url}
                />
            ))}
        </ScreenOuter>
    )
}

export default ImagesScreen
