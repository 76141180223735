import {Map} from 'immutable';

// action types
export const SET_IS_LOADING_ACCOMMODATION = 'Loading/SET_IS_LOADING_ACCOMMODATION'
export const SET_IS_LOADING_ROOM = 'Loading/SET_IS_LOADING_ROOM'
export const SET_IS_LOADING_RESERVATION = 'Loading/SET_IS_LOADING_RESERVATION'

// action creators
export const setIsLoading = (type: string, isLoading: boolean) => ({
    type: type,
    payload: {
        isLoading,
    }
})

// initialState
const initialState = Map({
    isLoadingAccommodation: false,
    isLoadingRoom: false,
    isLoadingReservation: false,
});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SET_IS_LOADING_ACCOMMODATION:
            return state.set('isLoadingAccommodation', action.payload.isLoading);
        case SET_IS_LOADING_ROOM:
            return state.set('isLoadingRoom', action.payload.isLoading);
        case SET_IS_LOADING_RESERVATION:
            return state.set('isLoadingReservation', action.payload.isLoading)
        default:
            return state;
    }
};
