import React, {CSSProperties, ReactNode} from "react";
import {Layout, Typography, Space, Row} from "antd";
import {useLocation, useNavigate} from "react-router-dom";

import './navbar.css'
import {Color, MobileSizeStyle, TextStyle} from "../../constants/style";
import {Content, Footer} from "antd/es/layout/layout";
import EmptySpace from "../EmptySpace";
import NoBorderButton from "../NoBorderButton";
import NavigationService from "../../services/NavigationService";
import {PageRoutes} from "../../App";
import ScreenGrid from "../ScreenGrid";
import {BackwardOutlined, LeftOutlined} from "@ant-design/icons";
import BlockScreenLoadingComponent from "../LoadingModal";

type HeaderType = 'menus' | 'back'

const ScreenOuter = ({
                         headerFixed = false,
                         headerType = 'menus',
                         headerTitle = '',
                         header = null,
                         footerBottom = 0,
                         accommodationName,
                         ...props
                     }: {
    headerFixed?: boolean,
    headerType?: HeaderType,
    headerTitle?: string,
    header?: ReactNode,
    footerBottom?: number,
    accommodationName?: String,
    children?: any
}) => {
    const {pathname: location} = useLocation();
    const fixedHeaderStyle: CSSProperties = {
        position: 'fixed',
        zIndex: 1,
    }

    let HeaderComponent = (
        <Layout.Header className="pc-nav-header" style={headerFixed ? fixedHeaderStyle : {}}>
            <Menus pathname={location}/>
        </Layout.Header>
    )

    if (headerType === 'back') {
        HeaderComponent = (
            <Layout.Header style={headerFixed ? fixedHeaderStyle : {}} className="pc-nav-header">
                <BackButtonHeader header={header} title={headerTitle || ''}/>
            </Layout.Header>
        )
    }

    return (
        <ScreenGrid footerBottom={footerBottom}>
            {HeaderComponent}
            {headerFixed && (<EmptySpace size={80}/>)}
            <Layout style={{backgroundColor: Color.white}}>
                {props.children}
            </Layout>
            <BlockScreenLoadingComponent/>
        </ScreenGrid>
    );
};

const Menus = ({pathname}: { pathname: string }) => (
    <Space style={{minWidth: 250, width: 250, alignItems: 'center', justifyContent: 'space-between'}}>
        <NoBorderButton
            style={{...fontStyleByPattern(pathname, /^\/accommodations/)}}
            value={'예약하기'}
            onClick={() => NavigationService.moveTo(PageRoutes.Accommodations)}
        />
        <NoBorderButton
            style={{...fontStyleByPattern(pathname, /^\/reservations\/search$/)}}
            value={'예약 확인'}
            onClick={() => NavigationService.moveTo(PageRoutes.ReservationSearchScreen)}
        />
        <NoBorderButton
            style={{...fontStyleByPattern(pathname, /^\/notifications$/)}}
            value={'공지사항'}
            onClick={() => NavigationService.moveTo(PageRoutes.Notifications)}
        />
    </Space>
)

const BackButtonHeader = ({title = '', header = null}: { title?: string, header?: ReactNode }) => {
    const navigate = useNavigate();
    return (
        <Row style={{
            ...MobileSizeStyle,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
        }}>
            <Space
                className={'clickable'}
                style={{alignItems: 'center', justifyContent: 'flex-start', marginTop: 3}}
                onClick={() => navigate(-1)}
            >
                <LeftOutlined/>
            </Space>
            <Space style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                {header ? (header) : (
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 700}}>
                        {title}
                    </Typography.Text>
                )}
            </Space>
        </Row>
    )
}

const fontStyleByPattern = (pathname: string, pattern: RegExp) => {
    if (pattern.test(pathname)) {
        return {
            ...TextStyle, fontSize: 16,
            fontWeight: 700,
            color: Color.darkPrimary,
        }
    }

    return {
        ...TextStyle, fontSize: 16,
        fontWeight: 500,
        color: Color.darkTertiary,
    }
}


export default ScreenOuter;
